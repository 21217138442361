import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useCallback, useMemo } from 'react';

/* --------------------------------------------------

    SORTABLE ITEM (internal)

-------------------------------------------------- */

const SortableItem = ({
  id,
  item,
  renderFunction,
  isDragged = false
}) => {
  /* -------------------------
      STATE
  ------------------------- */

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    activeIndex,
    index
  } = useSortable({ id });

  /* -------------------------
      RENDER
  ------------------------- */

  return renderFunction({
    item,
    ref: setNodeRef,
    props: {
      ...attributes,
      style: {
        transform: CSS.Transform.toString(transform),
      },
    },
    handleProps: listeners,
    isActive: index >= 0 && activeIndex === index,
    isDragged
  });
};

/* --------------------------------------------------

    SORTABLE

-------------------------------------------------- */

export const SortableList = ({
  items,
  getItemId,
  renderItem,
  onSort
}) => {
  /* -------------------------
      STATE
  ------------------------- */

  const itemIds = useMemo(() => items.map(getItemId), [items, getItemId]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  /* -------------------------
      HANDLERS
  ------------------------- */

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = itemIds.indexOf(active.id);
        const newIndex = itemIds.indexOf(over.id);

        if (onSort) onSort(active.id, oldIndex, newIndex);
      }
    },
    [itemIds, onSort]
  );

  /* -------------------------
      RENDER
  ------------------------- */

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {itemIds.map((id, i) => (
            <SortableItem
              key={i}
              id={id}
              item={items[i]}
              renderFunction={renderItem}
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};
