import * as React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import routes from '../routes';

import Header from '../components/header';
import Footer from '../components/footer';
import NotFound from './not-found';

// Layout général de l'app
// ici on ajoute les composants qui sont communs à toutes les pages
const Layout = () => {
  const location = useLocation();

  const pagesWithoutHeaders = ['/jeu'];

  const pagesWithoutFooter = ['/jeu'];

  return (
    <>
      {
        // Affichage du header sur les pages "back-office" seulement
      }
      {!pagesWithoutHeaders.some((page) => location.pathname.includes(page)) && (
        <Header />
      )}

      {
        // Reset du scroll au changement de page
      }
      <ScrollRestoration />

      {
        // Composant message toast global
      }
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={2}
      />

      {
        // Affichage des pages définies dans "routes"
      }
      <Outlet />

      {
        // Affichage du footer sur les pages "back-office" seulement
      }
      {!pagesWithoutFooter.some((page) => location.pathname.includes(page)) && (
        <Footer />
      )}
    </>
  )
};

let router = createBrowserRouter([
  {
    path: '/', 
    element: <Layout/>,
    errorElement: <NotFound />,
    children: routes,
  }
]);

export default function App() {
  return <RouterProvider router={router} />
}
