import * as React from 'react';
import './account.css';
import { toast } from 'react-toastify';

import * as apiUsers from '../../../services/api/user';

import CustomInput from '../../../components/custom-input';
import CustomLabel from '../../../components/custom-label';
import CustomButton from '../../../components/custom-button';

export default function AccountBackOffice() {
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [accountLoading, setAccountLoading] = React.useState(false);

  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [passwordLoading, setPasswordLoading] = React.useState(false);

  const [deleteAccountConfirmation, setDeleteAccountConfirmation] = React.useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = React.useState(false);

  React.useEffect(() => {
    // Récupération des infos du compte
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const tmpUser = await apiUsers.getMe();

      setUsername(tmpUser?.username || '');
      setEmail(tmpUser?.email || '');
    } catch {
      toast.error('Vos informations n\'ont pas pu être récupérées. Réessayez plus tard');
    }
  };

  const update = async (event) => {
    event.preventDefault();

    if (!username) {
      toast.error('Nom d\'utilisateur invalide');
      return;
    }

    setAccountLoading(true);

    // Mise à jour de l'utilisateur
    try {
      await apiUsers.update({
        username,
      });
      
      toast.success('Modification réussie !');
    } catch {
      toast.error('Échec de la modification, veuillez réessayer plus tard.');
    } finally {
      setAccountLoading(false);
    }
  };

  const updatePassword = async (event) => {
    event.preventDefault();

    if (!password1) {
      toast.error('Nouveau mot de passe invalide');
      return;
    } else if (!password2) {
      toast.error('Confirmation du mot de passe invalide');
      return;
    } else if (password1 !== password2) {
      toast.error('Les mots de passe doivent être identiques');
      return;
    } else if ((password1.length < 8 || password1.length > 64) || (password2.length < 8 || password2.length > 64)) {
      toast.error('Le mot de passe doit contenir entre 8 et 64 caractères !');
      return;
    }

    setPasswordLoading(true);

    // Modification du mot de passe du joueur
    try {
      await apiUsers.updatePassword({
        password: password2,
      });
      
      toast.success('Modification réussie !');

      // Reset des inputs de mot de passe
      setPassword1('');
      setPassword2('');
    } catch {
      toast.error('Échec de la modification, veuillez réessayer plus tard.');
    } finally {
      setPasswordLoading(false);
    }
  };

  const startDeleteAccount = async () => {
    setDeleteAccountConfirmation(true);
  };

  const deleteAccount = async () => {
    setDeleteAccountLoading(true);

    // Suppression de l'utilisateur
    try {
      await apiUsers.deleteAll();

      toast.success('Suppression réussie !');

      setTimeout(() => {
        apiUsers.disconnect();
      }, 1500);
    } catch {
      toast.error('Échec de la suppression, veuillez contacter le support.');
    }
  };

  return (
    <div className="container-account">
      <section className="container-title">
        <h1>Mon compte</h1>
      </section>

      <form className="form-data" onSubmit={update}>
        <CustomLabel label="Nom d'utilisateur" />
        <CustomInput
          value={username}
          onChange={setUsername}
          placeholder="ex : johndoe123"
          maxLength={128}
        />
        
        <CustomLabel label="Adresse email" />
        <CustomInput
          value={email}
          onChange={setEmail}
          placeholder="ex : john.doe@gmail.com"
          maxLength={128}
          disabled
        />

        <CustomButton label="Modifier les informations" loading={accountLoading} type="submit" />
      </form>

      <form className="form-password" onSubmit={updatePassword}>
        <CustomLabel label="Nouveau mot de passe" />
        <CustomInput
          value={password1}
          onChange={setPassword1}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
        />

        <CustomLabel label="Confirmation du mot de passe" />
        <CustomInput
          value={password2}
          onChange={setPassword2}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
        />

        <CustomButton label="Modifier le mot de passe" loading={passwordLoading} type="submit" />
      </form>

      <div className="form-delete">
        <CustomLabel label="Suppression du compte" />

        <p className="warning-note">Attention ! Cette action est irréversible. Toutes vos données seront supprimées et ne pourront pas être récupérées.</p>

        {!deleteAccountConfirmation && (
          <CustomButton label="Supprimer mon compte" onClick={startDeleteAccount} />
        )}

        {deleteAccountConfirmation && (
          <div className="delete-confirmation-container">
            <p>Êtes vous sûr de vouloir supprimer votre compte ?</p>

            <CustomButton
              label="Oui, je confirme vouloir supprimer mon compte"
              onClick={deleteAccount}
              loading={deleteAccountLoading}
              secondary
            />
          </div>
        )}
      </div>
    </div>
  );
}
