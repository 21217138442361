import { Link } from 'react-router-dom';

import './custom-text-link.css';

export default function CustomTextLink(props) {
  return (
    <Link
      className="custom-text-link"
      style={{
        color:
          props.color === 'ERROR' ? 'var(--error)' :
          props.color === 'PRIMARY' ? 'var(--primary)' :
          'var(--text)',
      }}
      to={props.to}
    >
      {props.label}
    </Link>
  );
}
