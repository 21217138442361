import * as React from 'react';
import './buzzer.css';

export default function Buzzer(props) {
  const [isClicked, setIsClicked] = React.useState(false);
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (!buttonRef?.current) return;

    if (props.trigger) {
      buttonRef.current.dispatchEvent(new Event('mousedown'));
    } else {
      buttonRef.current.dispatchEvent(new Event('mouseup'));
    }
  }, [props.trigger]);

  const mouseDown = () => {
    setIsClicked(true);

    if (props.onClick) props.onClick();
  };

  const mouseUp = () => {
    setIsClicked(false);
  };

  return (
    <button
      ref={buttonRef}
      className="buzzer"
      onMouseDownCapture={mouseDown}
      onMouseUpCapture={mouseUp}
    >
      <div className={`surface ${isClicked ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`}>BUZZER</div>
    </button>
  );
}
