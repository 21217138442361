import axios from 'axios';
import ls from 'local-storage';
import RouteNames from '../../route-names';

const API_URL = process.env.REACT_APP_API_URL;
export let isConnected = false;

/*
 * INIT
 * récupération du TOKEN en ls si existe
 * et mise en header par défaut
*/
const token = ls.get('TOKEN');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  isConnected = true;
}

/*
 * GESTION 401
*/
axios.interceptors.response.use(null, (error) => {
  if (
    error?.response?.status === 401 &&
    !error?.request?.responseURL?.includes('users/login')
    && !error?.request?.responseURL?.includes('users/register-form')
  ) {
    disconnect();
  }

  return Promise.reject(error);
});

export const register = async ({ email }) => {
  try {
    const response = await axios.post(`${API_URL}/users/register`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerForm = async (token, { password, username }) => {
  try {
    const response = await axios.post(
      `${API_URL}/users/register-form`,
      {
        password,
        username,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async ({ email }) => {
  try {
    const response = await axios.post(`${API_URL}/users/forgot-password`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordForm = async (token, { password }) => {
  try {
    const response = await axios.post(
      `${API_URL}/users/forgot-password-form`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async ({ email, password }) => {
  try {
    const response = await axios.post(`${API_URL}/users/login`, {
      email,
      password,
    });

    // Sauvegarde du token en local storage
    ls.set('TOKEN', response.data.token);
    
    isConnected = true;

    // Mise du token dans le header par défaut
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const disconnect = () => {
  ls.remove('TOKEN');
  axios.defaults.headers.common['Authorization'] = '';
  isConnected = false;

  // Redirection vers la page de login
  window.location = RouteNames.LOGIN;
};

export const update = async ({ username }) => {
  try {
    const response = await axios.put(`${API_URL}/users/me`, {
      username,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async ({ password }) => {
  try {
    const response = await axios.put(`${API_URL}/users/me/update-password`, {
      password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/users/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentSubscription = async () => {
  try {
    const response = await axios.get(`${API_URL}/users/me/subscription`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAll = async () => {
  try {
    await axios.delete(`${API_URL}/users/me`);
  } catch (error) {
    throw error;
  }
};
