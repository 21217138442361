import * as React from 'react';
import './player-result.css';

export default function PlayerResult(props) {
  const positionColors = {
    1: '#FFD700',
    2: '#C0C0C0',
    3: '#CD7F32',
  };

  const positionBackgroundColor = positionColors[props.position] || 'var(--card-background-darker)';

  return (
    <div className="player-result">
      <div
        className="container-position"
        style={{
          backgroundColor: positionBackgroundColor,
        }}
      >
        <p>{props.position}</p>
      </div>

      <div className="container-username">
        <p>{props.username}</p>
      </div>

      <div className="container-score">
        <p>{props.score} pts</p>
      </div>
    </div>
  );
}
