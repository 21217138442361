import './pricing.css';

import PricingCard from '../../components/pricing-card';

export default function Pricing() {
  return (
    <div className='pricing'>
      <h1>Tarification</h1>

      <div className="container-items-pricing">
        <div className="container-pricing">
          <PricingCard type="FREE" />
        </div>

        <div className="container-pricing">
          <PricingCard type="PREMIUM" />
        </div>
      </div>
    </div>
  );
}
