export default {
  HOME: '/',
  FORGOT_PASSWORD: '/mot-de-passe-oublie',
  FORGOT_PASSWORD_FORM: '/mot-de-passe-oublie-formulaire',
  REGISTER: '/inscription',
  REGISTER_FORM: '/inscription-formulaire',
  LOGIN: '/connexion',
  CHECKOUT: '/commande',
  PRICING: '/tarification',
  NOTICE: '/mentions-legales',
  PRIVACY: '/politique-de-confidentialite',
  TERMS: '/conditions-generales',
  QUIZZES: '/mes-quiz',
  ACCOUNT: '/mon-compte',

  GAME_REGISTER: '/jeu/rejoindre',
  GAME_LOBBY: '/jeu/salon',
  GAME_SESSION: '/jeu',

  ADMIN_LOBBY: '/jeu/animateur/salon',
  ADMIN_SESSION: '/jeu/animateur',
  ADMIN_SPECTATOR: '/jeu/spectateur',

  GAME_LEADERBOARD: '/jeu/classement',
};
