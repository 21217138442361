import { Link } from "react-router-dom";

import './footer.css';
import RouteNames from "../../route-names";

export default function Footer() {
  return (
    <div className="container-footer">
      <Link to={RouteNames.TERMS}>Conditions générales</Link>
      
      <Link to={RouteNames.PRIVACY}>Politique de confidentialité</Link>
      
      <Link to={RouteNames.NOTICE}>Mentions légales</Link>
      
      <a href="https://studiobleumoutarde.com/" target="_blank">
        @2024 StudioBleuMoutarde
      </a>
    </div>
  );
}
