import * as React from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';

import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import NotStarted from '../../player/session/components/not-started';
import Instructions from '../../player/session/components/instructions';
import CustomInput from '../../../components/custom-input';
import PropositionBloc from '../../player/components/proposition-bloc';
import PlayerDrawing from '../../../components/player-drawing';
import PlayerTextResponse from '../../../components/player-text-reponse';

import './admin-spectator.css';
import { ActionBack } from '../../../enums/action-back.enum';
import { ActionAdmin } from '../../../enums/action-admin.enum';

let question;
let setQuestion;

let hasBeenFirstQuestion;
let setHasBeenFirstQuestion;

let broadcastChannelSpectatorMode = null;

export default function AdminSpectator() {
  const initialQuestion = {
    title: '',
    theme: '',
    image: { credit: '', url: '' },
    audio: { credit: '', url: '' },
    propositions: null,
    response: '',
    explicitResponse: '',
    points: null,
    text: false,
    drawing: { active: false, credit: '', url: '' },
  };

  [hasBeenFirstQuestion, setHasBeenFirstQuestion] = React.useState(false);

  [question, setQuestion] = React.useState({});

  const [isDrawingDisabled, setIsDrawingDisabled] = React.useState(false);
  const [playerDrawing, setPlayerDrawing] = React.useState('');

  const [isTextResponseDisabled, setIsTextResponseDisabled] = React.useState(false);
  const [playerTextResponse, setPlayerTextResponse] = React.useState('');

  const hasAnyMainQuestionElement = !!question?.title
    || !!question?.audio?.url
    || !!question?.image?.url
    || !!question?.propositions
    || !!question?.response;

  React.useEffect(() => {
    // Connexion au Broadcast Channel pour la communication avec le mode spec
    broadcastChannelSpectatorMode = new BroadcastChannel('spectator-mode');

    broadcastChannelSpectatorMode.onmessage = (event) => {
      if (event?.data) {
        handleMessageFromParent(event.data);
      }
    };
  }, []);

  const handleMessageFromParent = (data) => {
    switch (data.action) {
      case ActionBack.BACK_Q_COMPLETE:
        if (!hasBeenFirstQuestion) setHasBeenFirstQuestion(true);

        // Ajoute les infos metadata de la question
        setQuestion({
          ...initialQuestion,
          points: data.currentQuestion.points,
          theme: data.currentQuestion.theme,
          text: data.currentQuestion.text,
          drawing: data.currentQuestion.drawing,
          ...(data.currentQuestion?.audio && { audio: data.currentQuestion.audio }),
        });

        // Reset disable dessin
        setIsDrawingDisabled(false);

        // Reset dessin joueur
        setPlayerDrawing('');

        // Reset disable input texte
        setIsTextResponseDisabled(false);

        // Reset réponse texte joueur
        setPlayerTextResponse('');

        break;
      case ActionBack.BACK_Q_TITLE:
        // Ajoute le titre de la question
        setQuestion({
          ...question,
          title: data.element,
        });

        break;
      case ActionBack.BACK_Q_PROP:
        // Ajoute les propositions de la question
        setQuestion({
          ...question,
          propositions: data.element,
        });

        break;
      case ActionBack.BACK_Q_RES:
        // Ajoute la réponse de la question
        setQuestion({
          ...question,
          response: data.element,
        });

        break;
      case ActionBack.BACK_Q_EXP_RES:
        // Ajoute la réponse explicite de la question
        setQuestion({
          ...question,
          explicitResponse: data.element,
        });

        break;
      case ActionBack.BACK_Q_IMAGE:
        // Ajoute l'image de la question
        setQuestion({
          ...question,
          image: data.element,
        });

        break;
      case ActionBack.BACK_SEND_DRAWING:
        // Disable le dessin
        setIsDrawingDisabled(true);

        break;
      case ActionAdmin.ADMIN_DISPLAY_DRAWING:
        // Afficher le dessin d'un joueur
        setPlayerDrawing({
          username: data?.username,
          value: data?.value,
        });

        break;
      case ActionBack.BACK_SEND_TEXT_RESPONSE:
        // Disable l'input texte
        setIsTextResponseDisabled(true);

        break;
      case ActionAdmin.ADMIN_DISPLAY_TEXT_RESPONSE:
        // Afficher la réponse texte d'un joueur
        setPlayerTextResponse({
          username: data?.username,
          value: data?.value,
        });

        break;
      default:
        break;
    }
  };

  const urlify = (text) => {
    if (!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex)
      .map(part => {
        if (part.match(urlRegex)) {
          return <a href={part} target="_blank" key={part}> {part} </a>;
        }
        return part;
      });
  };

  const creditImage = React.useMemo(() => urlify(question?.image?.credit), [question?.image?.credit]);
  const creditAudio = React.useMemo(() => urlify(question?.audio?.credit), [question?.audio?.credit]);

  return (
    <section className="container-admin-spectator">
      <div id="container-context" style={{ padding: !(question?.points || question?.theme) ? '0px' : '8px 24px' }}>
        {question?.points && (<p id="points">{question.points + ' pts'}</p>)}

        {question?.theme && (<p id="theme">{question.theme}</p>)}
      </div>

      {question?.title && (<h1>{question?.title}</h1>)}

      <div id="container-elements">
        {!hasBeenFirstQuestion && (
          <NotStarted />
        )}

        {hasBeenFirstQuestion && !hasAnyMainQuestionElement && (
          <Instructions />
        )}

        {question?.audio?.credit && question?.response && (
          <p id="credit">Source : {creditAudio}</p>
        )}

        {question?.audio?.url && (
          <div id="container-audio">
            <p className="indication">De l'audio sera joué pour cette question, vous pouvez régler le volume ici</p>

            <div id="audio-controls">
              <VolumeDownIcon sx={{ fontSize: 20 }} />

              <input type="range" min="0" max="100" />

              <VolumeUpIcon sx={{ fontSize: 20 }} />
            </div>
          </div>
        )}

        {question?.image?.credit && question?.response && (
          <p id="credit">Source : {creditImage}</p>
        )}

        {question?.image?.url && (
          <img src={question.image.url} alt="illustration de la question" />
        )}

        {/* On affiche le text input pour envoyer la réponse quand le titre de la question est affiché */}
        {question?.text && question?.title && (
          <>
            <CustomInput
              placeholder="ex : Vincent Van Gogh"
              disabled={isTextResponseDisabled}
            />

            <p className="indication">
              {isTextResponseDisabled ? 
                `L'animateur a récupéré votre réponse, vous ne pouvez plus la modifier`
                :
                `Tapez votre réponse et attendez que l'animateur collecte les réponses. Attention : vous ne pourrez plus modifier votre réponse une fois qu'elle sera collectée !`
              }
            </p>

            {playerTextResponse && (
              <PlayerTextResponse
                username={playerTextResponse.username}
                value={playerTextResponse.value}
              />
            )}
          </>
        )}

        {/* On affiche la zone de dessin pour envoyer le dessin quand le titre de la question est affiché */}
        {question?.drawing?.active && question?.title && (
          <>
            {!isDrawingDisabled && (
              <ReactSketchCanvas
                className={question?.drawing?.url && 'with-background'}
                style={{
                  width: '500px',
                  height: '500px',
                  border: "0.0625rem solid #9c9c9c",
                  ...(question?.drawing?.url && {
                    backgroundImage: `url(${question.drawing.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }),
                }}
                strokeWidth={4}
                strokeColor="black"
                readOnly
              />
            )}

            <p className="indication">
              {isDrawingDisabled ? 
                `L'animateur a récupéré votre dessin`
                :
                `Dessinez votre réponse et attendez que l'animateur collecte les dessins. Attention : vous ne pourrez plus modifier votre dessin une fois qu'il aura été collecté !`
              }
            </p>

            {playerDrawing && (
              <PlayerDrawing
                username={playerDrawing.username}
                path={playerDrawing.value}
                backgroundUrl={question?.drawing?.url}
              />
            )}
          </>
        )}

        {question?.propositions && (
          <div id="container-propositions">
            {question.propositions.map((prop) => (
              <PropositionBloc
                key={prop.id}
                label={prop.label}
                isResponse={question?.response && question.response === prop.id}
              />
            ))}
          </div>
        )}

        {question?.explicitResponse && (
          <p id="credit">Informations additionnelles : {question.explicitResponse}</p>
        )}
      </div>
    </section>
  );
}
