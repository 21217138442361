import React from 'react';
import { useNavigate } from 'react-router-dom';
import ls from 'local-storage';

import './pricing-card.css';

import * as apiUser from '../../services/api/user';
import RouteNames from '../../route-names';

export default function PricingCard(props) {
  const navigate = useNavigate();
  
  const [isSubscribed, setIsSubscribed] = React.useState(false);

  React.useEffect(() => {
    if (apiUser.isConnected && props.type !== 'FREE') {
      getCurrentSubscription();
    }
  }, []);

  const chooseFree = () => {
    if (apiUser.isConnected) {
      navigate(RouteNames.QUIZZES);
    } else {
      navigate(RouteNames.REGISTER);
    }
    
  };

  const choosePremium = () => {
    if (apiUser.isConnected) {
      // Récupération du token en ls
      const token = ls.get('TOKEN');

      // Redirection vers le formulaire de paiement Stripe
      window.open(`${process.env.REACT_APP_API_URL}/stripe/checkout?token=${token}`, '_blank');
    } else {
      navigate(RouteNames.REGISTER);
    }
  };

  const getCurrentSubscription = async () => {
    try {
      const sub = await apiUser.getCurrentSubscription();

      setIsSubscribed(sub.type === 'PREMIUM');
    } catch {
      //
    }
  };

  const freeData = {
    title: 'BASIQUE',
    price: '0',
    subPrice: 'gratuit',
    features: [
      '40 questions par quiz',
      '3 joueurs par session',
      '2 quiz en simultané',
    ],
    actionLabel: apiUser.isConnected ? 'Actif' : `S'inscrire`, // Si l'utilisateur n'est pas connecté alors le bouton affiche "S'inscrire", sinon si l'utilisateur est connecté alors le bouton affiche "Actif"
    action: chooseFree,
  };
  const premiumData = {
    title: 'COMPLET',
    price: '3.99',
    subPrice: 'payez une seule fois',
    features: [
      '80 questions par quiz',
      '10 joueurs par session',
      '10 quiz en simultané',
    ],
    actionLabel: apiUser.isConnected ? isSubscribed ? `Actif` : `Débloquer` : `S'inscrire`, // Si l'utilisateur est connecté, si il est sub alors "Actif", sinon "Débloquer". Si l'utilisateur n'est pas connecté alors "S'inscrire"
    action: choosePremium,
  };

  const displayData = props.type === 'FREE' ? freeData : premiumData;

  let isActionButtonDisabled = false;
  if (props.type === 'FREE' && apiUser.isConnected) {
    isActionButtonDisabled = true;
  }

  if (props.type === 'PREMIUM' && apiUser.isConnected && isSubscribed) {
    isActionButtonDisabled = true;
  }

  return (
    <div className="pricing-card">
      <h2>{displayData.title}</h2>

      <div className="container-price">
        <p className="price">{displayData.price}€</p>
        <p className="subprice">{displayData.subPrice}</p>
      </div>

      {displayData.features.map((feature, index) => (
        <p className="feature" key={index}>{feature}</p>
      ))}

      {(props.type === 'PREMIUM' || (props.type === 'FREE' && !isActionButtonDisabled)) && (
        <button type="button" onClick={displayData.action} disabled={isActionButtonDisabled}>
          <p>{displayData.actionLabel}</p>
        </button>
      )}
    </div>
  );
}
