import * as React from 'react';
import './not-started.css';

import InstructionCard from '../../../../../components/instruction-card/instruction-card';

export default function NotStarted() {
  return (
    <div className="not-started-admin-session">
      <div className="container-cards">
        <InstructionCard title="Sélectionnez" content={`Naviguez entre les questions avec les boutons "question précédente" et "question suivante"`} />

        <InstructionCard title="Questionnez" content="Posez les questions à l'oral" />

        <InstructionCard title="Affichez" content="Envoyez les éléments des questions aux joueurs (titre, image, audio, propositions)" />

        <InstructionCard title="Validez" content="Lorsqu'un joueur buzze, validez ou non sa réponse" />
      </div>

      <p className="indication">Lancez la première question quand tout le monde est prêt, en passant à la question suivante</p>

      <p className="indication">Plus bas sur la page, vous avez une fenêtre spectateur qui vous montre ce que les joueurs voient sur leurs écrans</p>
    </div>
  );
}
