import * as React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

import './custom-text-button.css';

export default function CustomTextButton(props) {
  const [isConfirm, setIsConfirm] = React.useState(false);

  const action = () => {
    // Si le bouton n'a pas besoin de confirmation
    // on appelle directement la fonction voulue
    if (!props.confirmation) {
      props.onClick();
      return;
    }

    // Si le bouton a besoin de confirmation
    // on affiche le message de confirmation au premier clic
    if (isConfirm) {
      // On appelle la fonction attendue
      props.onClick();

      setIsConfirm(false);
    } else {
      // On affiche un message de confirmation
      setIsConfirm(true);
    }
  };

  return (
    <button
      className="custom-text-button"
      style={{
        color: props.secondary ? 'var(--error)' : 'var(--primary)',
      }}
      onClick={action}
      disabled={props.disabled}
      type="button"
    >
      {isConfirm
        ? (
          <div className="container-confirm">
            <WarningIcon sx={{ fontSize: 18 }} />
            <p>confirmer</p>
          </div>
        )
        : (
          <p>{props.label}</p>
        )
      }
    </button>
  );
}
