import * as React from 'react';
import './register-form.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import * as apiUsers from '../../../../services/api/user';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';
import RouteNames from '../../../../route-names';

export default function RegisterDataBackOffice() {
  const location = useLocation();
  const navigate = useNavigate();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const registerForm = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!username) {
      toast.error('Nom d\'utilisateur invalide');
      setLoading(false);
      return;
    } else if (password !== password2) {
      toast.error('Les mots de passe ne correspondent pas !');
      setLoading(false);
      return;
    } else if ((password.length < 8 || password.length > 64) || (password2.length < 8 || password2.length > 64)) {
      toast.error('Le mot de passe doit contenir entre 8 et 64 caractères !');
      setLoading(false);
      return;
    } else if (!acceptedTerms) {
      toast.error('Vous devez accepter les conditions générales');
      setLoading(false);
      return;
    }

    // Inscription du joueur
    try {
      const token = new URLSearchParams(location.search).get('token');
      await apiUsers.registerForm(token, {
        username,
        password,
      });
      
      toast.success('Inscription réussie !');

      // Redirection vers la page de login
      navigate(RouteNames.LOGIN);
    } catch {
      toast.error('Échec de l\'inscription.');
    } finally {
      setLoading(false);
    }
  };

  const checkAcceptedTerms = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  return (
    <div className="container-register-form">
      <h1>Inscription</h1>

      <form onSubmit={registerForm}>
        <CustomLabel label="Nom d'utilisateur" />
        <CustomInput
          value={username}
          onChange={setUsername}
          placeholder="ex : johndoe"
          maxLength={128}
          autoFocus
        />

        <CustomLabel label="Mot de passe" />
        <CustomInput
          value={password}
          onChange={setPassword}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
        />

        <CustomLabel label="Confirmation mot de passe" />
        <CustomInput
          value={password2}
          onChange={setPassword2}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
        />

        <div id="container-terms">
          <input type="checkbox" checked={acceptedTerms} onChange={checkAcceptedTerms} />

          <p>Je reconnais avoir lu et accepté l'ensemble des <Link to={RouteNames.TERMS} target="_blank">conditions générales</Link></p>
        </div>

        <CustomButton label="S'inscrire" loading={loading} type="submit" />
      </form>
    </div>
  );
}
