import * as React from 'react';
import './not-started.css';

import InstructionCard from '../../../../../components/instruction-card/instruction-card';

export default function NotStarted() {
  return (
    <div className="not-started-player-session">
      <div className="container-cards">
        <InstructionCard title="Ecoutez" content="L'animateur énoncera les questions à l'oral" />

        <InstructionCard title="Regardez" content="L'animateur vous enverra les éléments des questions (titre, image, audio, propositions)" />

        <InstructionCard title="Répondez" content="Appuyez sur le buzzer pour prendre la main et proposer une réponse" />
      </div>

      <p className="indication">Attendez que l'animateur lance la première question</p>
    </div>
  );
}
