import * as React from 'react';

import { ReactSketchCanvas } from 'react-sketch-canvas';
import CheckIcon from '@mui/icons-material/Check';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';

import './player-drawing.css';

import CustomButton from '../custom-button';

export default function PlayerDrawing(props) {
  const drawingRef = React.useRef(null);

  /* Effet pour remplir le dessin lorsqu'il est dispo et que leur ref est active */
  React.useEffect(() => {
    if (drawingRef && props.path) {
      drawingRef?.current?.clearCanvas();

      try {
        drawingRef?.current?.loadPaths(JSON.parse(props.path));
      } catch {
        //
      }
    }
  }, [props.path, drawingRef]);

  const urlify = (text) => {
    if (!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex)
      .map(part => {
        if (part.match(urlRegex)) {
          return <a href={part} target="_blank" key={part}> {part} </a>;
        }
        return part;
      });
  };

  const creditDrawing = React.useMemo(() => urlify(props.backgroundUrl), [props.backgroundUrl]);

  const hasActions = props.onGoodAnswer || props.displayDrawingToPlayers;

  return (
    <div className="player-drawing">
      <div
        className="container-drawing"
        style={{
          borderRadius: hasActions ? `8px 8px 0px 0px` : `8px`,
        }}
      >
        <p className="username">{props.username}</p>

        <ReactSketchCanvas
          ref={drawingRef}
          className={props?.backgroundUrl && 'with-background'}
          style={{
            width: '500px',
            height: '500px',
            border: "0.0625rem solid #9c9c9c",
            ...(props?.backgroundUrl && {
              backgroundImage: `url(${props.backgroundUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }),
          }}
          strokeWidth={4}
          strokeColor="black"
          readOnly={true}
        />

        {props?.backgroundUrl && (
          <p className="credit">Source de l'image de fond du dessin : {creditDrawing}</p>
        )}
      </div>

      {hasActions && (
        <div className="container-actions">
          {props.onGoodAnswer && (
            <div className="line validate-answer">
              <p>bonne réponse ?</p>
              <CustomButton
                label=""
                onClick={props.onGoodAnswer}
                rightIcon={<CheckIcon sx={{ fontSize: 16 }}
              />} />
            </div>
          )}

          {props.displayDrawingToPlayers && (
            <div className="line show-all">
              <p>afficher aux joueurs</p>
              <CustomButton
                label=""
                onClick={props.displayDrawingToPlayers}
                rightIcon={<PresentToAllIcon sx={{ fontSize: 16 }}
              />} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
