import * as React from 'react';
import dayjs from 'dayjs';
import * as dayjsFrench from 'dayjs/locale/fr';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import './quizz-card.css';

export default function QuizzCard({ label, onClick, createdAt }) {
  dayjs.extend(localizedFormat);
  dayjs.locale(dayjsFrench);

  let formattedCreatedAt = 'pas encore démarré';
  if (createdAt) {
    formattedCreatedAt = dayjs(createdAt).format('lll');
  }

  return (
    <div className="container-quizzcard" onClick={onClick}>
      <h1>{label}</h1>
      <p>créé le : {formattedCreatedAt}</p>
    </div>
  );
}
