import CheckIcon from '@mui/icons-material/Check';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';

import './player-text-response.css';

import CustomButton from '../custom-button';

export default function PlayerTextResponse(props) {
  const hasActions = props.onGoodAnswer || props.displayToPlayers;

  return (
    <div className="player-text-response">
      <div
        className="container-texts"
        style={{
          borderRadius: hasActions ? `8px 8px 0px 0px` : `8px`,
        }}
      >
        <p className="username">{props.username}</p>
        <p className="value">{props.value}</p>
      </div>

      {hasActions && (
        <div className="container-actions">
          {props.onGoodAnswer && (
            <div className="line validate-answer">
              <p>bonne réponse ?</p>
              <CustomButton
                label=""
                onClick={props.onGoodAnswer}
                rightIcon={<CheckIcon sx={{ fontSize: 16 }}
              />} />
            </div>
          )}

          {props.displayToPlayers && (
            <div className="line show-all">
              <p>afficher aux joueurs</p>
              <CustomButton
                label=""
                onClick={props.displayToPlayers}
                rightIcon={<PresentToAllIcon sx={{ fontSize: 16 }}
              />} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
