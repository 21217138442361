import * as React from 'react';
import './stroke-button.css';

import Loader from '../loader';

export default function StrokeButton(props) {
  return (
    <button
      className="stroke-button"
      onClick={props.onClick}
      style={{
        cursor: props.loading ? 'default' : 'pointer',
      }}
      disabled={props.loading}
    >
      {props.label}
      <Loader loading={props.loading} />
    </button>
  );
}
