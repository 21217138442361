import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './lobby.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import apiQuizzes from '../../../services/api/quiz';

import * as socket from '../../../services/websocket';
import { ActionBack } from '../../../enums/action-back.enum';
import { ActionPlayer } from '../../../enums/action-player.enum';
import PlayerCard from '../../../components/player-card';
import CustomLabel from '../../../components/custom-label';

import logo from '../../../assets/logo.png';
import RouteNames from '../../../route-names';

export default function LobbyGame() {
  const navigate = useNavigate();

  const [quizz, setQuizz] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [playerId, setPlayerId] = React.useState('');

  React.useEffect(() => {
    // Récupérer les infos de la Game
    getQuizz();

    socket.setOnMessage(handleMesagesFromWsServer, refreshPlayerList);

    setPlayerId(localStorage.getItem('PLAYER_ID'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizz = async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByPlayerJwtToken();

      setQuizz(lightQuizz);
      setAdmin(lightQuizz.admin);
    } catch (err) {
      toast.error('Impossible de récupérer les infos du quiz');
    }
  };

  const handleMesagesFromWsServer = (message) => {
    if (message?.action === ActionBack.BACK_PLAYER_LIST) {
      // Rafraichir liste des joueurs
      setPlayers(message.players);
    } else if (message?.action === ActionBack.BACK_START_QUIZZ) {
      // Rediriger vers l'ecran de jeu
      navigate(RouteNames.GAME_SESSION);
    } else if (message?.action === ActionBack.BACK_P_BEEN_EXCLUDED) {
      // Process d'exclusion
      socket.cleanUpExcluded();
    }
  };

  const refreshPlayerList = () => {
    socket.sendMessage({
      action: ActionPlayer.PLAYER_LIST,
    });
  };

  return (
    <div className="container-player-lobby">
      <Link className="container-logo" to="/" target="_blank">
        <img className="logo" src={logo} />
      </Link>

      <h1 className="title">{quizz?.label}</h1>

      <div className="container-content">
        <CustomLabel label="Animateur" />

        {admin?.username && (
          <PlayerCard
            content={admin.username}
          />
        )}

        <CustomLabel label="Participants connectés" />

        {players.map((player) => (
          <PlayerCard
            key={player.playerId}
            content={player.username}
            isMe={player.playerId === playerId}
          />
        ))}

        <p className="indication">En attente du lancement de la session par l'animateur</p>
      </div>
    </div>
  );
}
