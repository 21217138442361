import StrokeButton from '../../stroke-button/stroke-button';
import StrokeLink from '../../stroke-link/stroke-link';
import CustomTextLink from '../../custom-text-link';
import RouteNames from '../../../route-names';

export default function InlineHeader(props) {
  return (
    <div className="container-inline-header">
      {!props?.isConnected && (
        <div className="contentRightContainer">
          <CustomTextLink to={RouteNames.PRICING} label="Tarification" onClick={props.navigateToPricing} />

          <CustomTextLink to={RouteNames.LOGIN} label="Connexion" onClick={props.navigateToLogin} />

          <StrokeLink label="S'inscrire" onClick={props.navigateToRegister} to={RouteNames.REGISTER} />
        </div>
      )}

      {props?.isConnected && (
        <div className="contentRightContainer">
          <CustomTextLink to={RouteNames.PRICING} label="Tarification" onClick={props.navigateToPricing} />

          <CustomTextLink to={RouteNames.QUIZZES} label="Mes quiz" onClick={props.navigateToQuizzes} />

          <CustomTextLink to={RouteNames.ACCOUNT} label="Mon compte" onClick={props.navigateToAccount} />

          <StrokeButton label="Se déconnecter" onClick={props.disconnect} />
        </div>
      )}
    </div>
  );
}
