import * as React from 'react';
import './login.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import * as apiUsers from '../../../../services/api/user';
import RouteNames from '../../../../route-names';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';
import CustomTextLink from '../../../../components/custom-text-link';

export default function LoginBackOffice() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const login = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Connexion du joueur
    try {
      await apiUsers.login({
        email,
        password,
      });

      // Rediriger vers l'ecran de liste des quiz
      navigate(RouteNames.QUIZZES);
    } catch (err) {
      toast.error('Mail ou mot de passe incorrect');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-login">
      <h1>Connexion</h1>

      <form onSubmit={login}>
        <CustomLabel label="Adresse email" />
        <CustomInput value={email} onChange={setEmail} placeholder="ex : john.doe@email.com" maxLength={128} autoFocus />

        <CustomLabel label="Mot de passe" />
        <CustomInput value={password} onChange={setPassword} type="password" maxLength={64} />

        <CustomButton label="Se connecter" loading={loading} type="submit" />
      </form>

      <CustomTextLink to={RouteNames.FORGOT_PASSWORD} label="Vous avez oublié votre mot de passe ?" color="PRIMARY" />
    </div>
  );
}
