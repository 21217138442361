import axios from 'axios';

const API_URL_BASE = `${process.env.REACT_APP_API_URL}`;
const API_URL = `${API_URL_BASE}/quizzes`;

const getQuestions = async (quizzId) => {
  try {
    const response = await axios.get(`${API_URL}/${quizzId}/questions`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getQuestion = async (quizzId, questionId) => {
  try {
    const response = await axios.get(`${API_URL}/${quizzId}/questions/${questionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createQuestion = async (quizzId, data) => {
  try {
    const response = await axios.post(`${API_URL}/${quizzId}/questions`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createRandomQuestions = async (quizzId, data) => {
  try {
    const response = await axios.post(`${API_URL}/${quizzId}/questions/random`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateQuestion = async (quizzId, questionId, data) => {
  try {
    const response = await axios.put(`${API_URL}/${quizzId}/questions/${questionId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteQuestion = async (quizzId, questionId) => {
  try {
    const response = await axios.delete(`${API_URL}/${quizzId}/questions/${questionId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getQuestionsThemes = async () => {
  try {
    const response = await axios.get(`${API_URL_BASE}/questions/themes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getQuestions = getQuestions;
api.getQuestion = getQuestion;
api.createQuestion = createQuestion;
api.updateQuestion = updateQuestion;
api.deleteQuestion = deleteQuestion;
api.getQuestionsThemes = getQuestionsThemes;
api.createRandomQuestions = createRandomQuestions;

export default api;
