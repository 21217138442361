import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getQuizzes = async (light) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes`, {
      params: {
        light: !!light,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getQuizz = async (quizzId) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes/${quizzId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createQuizz = async () => {
  try {
    const response = await axios.post(`${API_URL}/quizzes`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateQuizz = async (quizzId, data) => {
  try {
    const response = await axios.put(`${API_URL}/quizzes/${quizzId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteQuizz = async (quizzId) => {
  try {
    const response = await axios.delete(`${API_URL}/quizzes/${quizzId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const startQuizz = async (quizzId) => {
  try {
    const response = await axios.post(`${API_URL}/quizzes/${quizzId}/start`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const endQuizz = async (quizzId) => {
  try {
    const response = await axios.post(`${API_URL}/quizzes/${quizzId}/end`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getQuizzByGameToken = async (gameToken) => {
  try {
    const response = await axios.get(`${API_URL}/quizzes/${gameToken}/game`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getQuizzByPlayerJwtToken = async () => {
  try {
    const gameToken = localStorage.getItem('GAME_TOKEN');

    if (!gameToken) throw new Error();

    const response = await axios.get(
      `${API_URL}/quizzes/player-token/game-by-token`,
      {
        headers: {
          Authorization: `Bearer ${gameToken}`,
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getQuizzes = getQuizzes;
api.getQuizz = getQuizz;
api.createQuizz = createQuizz;
api.updateQuizz = updateQuizz;
api.deleteQuizz = deleteQuizz;
api.startQuizz = startQuizz;
api.endQuizz = endQuizz;
api.getQuizzByGameToken = getQuizzByGameToken;
api.getQuizzByPlayerJwtToken = getQuizzByPlayerJwtToken;

export default api;
