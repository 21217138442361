import * as React from 'react';
import './forgot-password-form.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import * as apiUsers from '../../../../services/api/user';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';
import RouteNames from '../../../../route-names';

export default function ForgotPasswordFormBackOffice() {
  const location = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const forgotPasswordForm = async (event) => {
    event.preventDefault();

    if (!password) {
      toast.error('Mot de passe invalide');
      return;
    } else if (password !== password2) {
      toast.error('Les mots de passe ne correspondent pas !');
      return;
    }

    setLoading(true);

    // Changement de mot de passe
    try {
      const token = new URLSearchParams(location.search).get('token');

      await apiUsers.forgotPasswordForm(token, {
        password,
      });
      
      toast.success('Modification réussie !');

      // Redirection vers la page de login
      navigate(RouteNames.LOGIN);
    } catch (err) {
      toast.error('Modification impossible');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-forgot-password-form">
      <h1>Mot de passe oublié</h1>

      <form onSubmit={forgotPasswordForm}>
        <CustomLabel label="Mot de passe" />
        <CustomInput
          value={password}
          onChange={setPassword}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
          autoFocus
        />

        <CustomLabel label="Confirmation mot de passe" />
        <CustomInput
          value={password2}
          onChange={setPassword2}
          placeholder="Entre 8 et 64 caractères"
          minLength={8}
          maxLength={64}
          type="password"
        />

        <CustomButton label="Changer le mot de passe" loading={loading} type="submit" />
      </form>
    </div>
  );
}
