import * as React from "react";
import * as ReactDOM from "react-dom/client";

import App from './screens/app';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <App />
  </>
);
