import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import './proposition-bloc.css';

export default function PropositionBloc(props) {
  const placeholderValues = [
    'ex : Léonard de Vinci',
    'ex : Andrea del Verrocchio',
    'ex : Francesco Melzi',
    'ex : Sandro Botticelli',
  ];

  const actionDelete = (event) => {
    event.preventDefault();

    props?.delProp(props.index);
  };

  const actionChange = (event) => {
    props?.onChange(props.index, event);
  };

  const actionChooseResponse = (event) => {
    event.preventDefault();

    props?.chooseResponse(props.index);
  };

  if (!props?.isDefined) return null;

  return (
    <div className="container-proposition-bloc">
      <div
        className="prop-defined"
        style={{
          backgroundColor: props?.isResponse ? 'var(--green-opacity)' : 'var(--card-background)',
          border: props?.isResponse ? '1px solid var(--green)' : null,
        }}
      >
        <button id="close-button" onClick={actionDelete}>
          <CloseIcon sx={{ fontSize: 20 }} />
        </button>

        <textarea
          id="proposition-bloc-textarea"
          type="text"
          value={props.label}
          onChange={actionChange}
          placeholder={placeholderValues[props?.index || 0]}
          maxLength={32}
        ></textarea>

        {!props?.isResponse && (
          <div id="container-response-button">
            <button onClick={actionChooseResponse}>
              choisir comme bonne réponse
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
