import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './header.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import * as apiUser from '../../services/api/user';

import logo from '../../assets/logo.png';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import InlineHeader from './components/inline-header';
import ColumnHeader from './components/column-header';
import RouteNames from '../../route-names';

export default function Header() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);

  const triggerMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const navigateToRegister = () => {
    setIsOpen(false);
  };

  const navigateToLogin = () => {
    setIsOpen(false);
  };

  const navigateToHome = () => {
    navigate(RouteNames.HOME);
    setIsOpen(false);
  };

  const navigateToQuizzes = () => {
    setIsOpen(false);
  };

  const navigateToAccount = () => {
    setIsOpen(false);
  };

  const navigateToPricing = () => {
    setIsOpen(false);
  };

  const disconnect = () => {
    apiUser.disconnect();
    setIsOpen(false);
  };

  return (
    <div className="container-header">
      <div className="header-content">
        <div className="contentLeftContainer">
          <button onClick={navigateToHome}>
            <img src={logo} />
          </button>
        </div>

        <button className="menu-icon" onClick={triggerMenu}>
          {isOpen
            ? (
              <CloseIcon sx={{ fontSize: 28 }} />
            )
            : (
              <MenuIcon sx={{ fontSize: 28 }} />
            )
          }
        </button>

        <InlineHeader
          isConnected={apiUser.isConnected}
          navigateToLogin={navigateToLogin}
          navigateToRegister={navigateToRegister}
          navigateToQuizzes={navigateToQuizzes}
          navigateToAccount={navigateToAccount}
          navigateToPricing={navigateToPricing}
          disconnect={disconnect}
        />
      </div>

      {isOpen && (
        <ColumnHeader
          isConnected={apiUser.isConnected}
          navigateToLogin={navigateToLogin}
          navigateToRegister={navigateToRegister}
          navigateToQuizzes={navigateToQuizzes}
          navigateToAccount={navigateToAccount}
          navigateToPricing={navigateToPricing}
          disconnect={disconnect}
        />
      )}

      <Breadcrumbs />
    </div>
  );
}
