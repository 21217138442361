import * as React from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import './question-bloc.css';
import CustomTextButton from '../../../../../components/custom-text-button';
import Modal from '../../../../../components/modal/modal';

export default function QuestionBloc({ props, action, actionSecondary }) {
  const [isModalDeleteQuestionVisible, setIsModalDeleteQuestionVisible] = React.useState(false);

  const openModal = () => {
    setIsModalDeleteQuestionVisible(true);
  };

  const closeModal = () => {
    setIsModalDeleteQuestionVisible(false);
  };

  const actionTrigger = () => {
    action();

    setIsModalDeleteQuestionVisible(false);
  };

  const actionSecondaryTrigger = () => {
    actionSecondary();

    setIsModalDeleteQuestionVisible(false);
  };

  return (
    <div
      className="container-question-bloc"
      ref={props?.ref}
      {...props?.props}
      style={{
        ...props?.props?.style,
        opacity: props?.isActive ? 0.75 : 1,
      }}
    >
      <button
        className="dnd-handler"
        style={{
          cursor: props?.isActive ? 'grabbing' : 'grab',
        }}
        {...props?.handleProps}>
        <DragIndicatorIcon sx={{ fontSize: 24 }} />
      </button>

      <div className="container-position">
        <div
          className="circle-position"
          style={{
            backgroundColor: 'var(--color-1)',
          }}
        >
          {props?.item?.position || ''}
        </div>
      </div>

      <div className="container-texts">
        <p>
          {props?.item?.title || ''}
        </p>

        <div className="container-buttons">
          <CustomTextButton label="éditer" onClick={actionTrigger} />
          
          <CustomTextButton label="supprimer" secondary onClick={openModal} />
        </div>

      {isModalDeleteQuestionVisible && (
        <Modal isOpen={isModalDeleteQuestionVisible} content={`Êtes-vous sûr de vouloir supprimer la question "${props?.item?.title}" ?`} onConfirm={actionSecondaryTrigger} onClose={closeModal} />
      )}
      </div>
    </div>
  );
}
