import { useEffect, useState } from 'react';

const useTimer = () => {
  let timerInterval: any = null;
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    startTimer();

    return endTimer;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const startTimer = () => {
    timerInterval = setInterval(() => {
      setTimer(() => timer + 1);
    }, 1000);
  };

  const endTimer = () => {
    clearInterval(timerInterval);
  };

  const resetTimer = () => {
    setTimer(() => 0);
  };

  return [timer, resetTimer];
};

export { useTimer };
