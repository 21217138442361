import Home from './screens/home';
import Checkout from './screens/checkout';
import Pricing from './screens/pricing';
import Notice from './screens/notice';
import Privacy from './screens/privacy';
import Terms from './screens/terms';

import RegisterBackOffice from './screens/back-office/auth/register';
import RegisterFormBackOffice from './screens/back-office/auth/register-form';
import LoginBackOffice from './screens/back-office/auth/login';
import ForgotPasswordBackOffice from './screens/back-office/auth/forgot-password';
import ForgotPasswordFormBackOffice from './screens/back-office/auth/forgot-password-form';
import ListQuizBackOffice from './screens/back-office/quiz/list';
import DetailQuizBackOffice from './screens/back-office/quiz/detail';
import FormQuestionBackOffice from './screens/back-office/quiz/form-question';
import FormRandomQuestionBackOffice from './screens/back-office/quiz/form-random-question';
import AccountBackOffice from './screens/back-office/account/account';

import RegisterGame from './screens/player/register';
import LobbyGame from './screens/player/lobby';
import SessionGame from './screens/player/session';

import AdminLobby from './screens/admin/lobby';
import AdminSession from './screens/admin/session';
import AdminSpectator from './screens/admin/spectator';

import Leaderboard from './screens/player/leaderboard';

import RouteNames from './route-names';

const routes = [
  {
    path: RouteNames.HOME,
    element: <Home />,
  },
  {
    path: RouteNames.REGISTER,
    element: <RegisterBackOffice />,
  },
  {
    path: RouteNames.REGISTER_FORM,
    element: <RegisterFormBackOffice />,
  },
  {
    path: RouteNames.LOGIN,
    element: <LoginBackOffice />,
  },
  {
    path: RouteNames.FORGOT_PASSWORD,
    element: <ForgotPasswordBackOffice />,
  },
  {
    path: RouteNames.FORGOT_PASSWORD_FORM,
    element: <ForgotPasswordFormBackOffice />,
  },
  {
    path: RouteNames.CHECKOUT,
    element: <Checkout />,
  },
  {
    path: RouteNames.PRICING,
    element: <Pricing />,
  },
  {
    path: RouteNames.NOTICE,
    element: <Notice />,
  },
  {
    path: RouteNames.PRIVACY,
    element: <Privacy />,
  },
  {
    path: RouteNames.TERMS,
    element: <Terms />,
  },
  {
    path: RouteNames.QUIZZES,
    element: <ListQuizBackOffice />,
  },
  {
    path: `${RouteNames.QUIZZES}/:quizzId`,
    element: <DetailQuizBackOffice />,
    handle: {
      goBack: RouteNames.QUIZZES,
    },
  },
  {
    path: `${RouteNames.QUIZZES}/:quizzId/creer-question`,
    element: <FormQuestionBackOffice />,
    handle: {
      goBack: `${RouteNames.QUIZZES}/{{quizzId}}`,
    },
  },
  {
    path: `${RouteNames.QUIZZES}/:quizzId/creer-question-aleatoire`,
    element: <FormRandomQuestionBackOffice />,
    handle: {
      goBack: `${RouteNames.QUIZZES}/{{quizzId}}`,
    },
  },
  {
    path: `${RouteNames.QUIZZES}/:quizzId/editer-question/:questionId`,
    element: <FormQuestionBackOffice />,
    handle: {
      goBack: `${RouteNames.QUIZZES}/{{quizzId}}`,
    },
  },
  {
    path: RouteNames.ACCOUNT,
    element: <AccountBackOffice />,
  },
  /**
   * **********************
   * ROUTES GAME JOUEURS
   * **********************
   */
  {
    path: `${RouteNames.GAME_REGISTER}/:token`,
    element: <RegisterGame />,
  },
  {
    path: RouteNames.GAME_LOBBY,
    element: <LobbyGame />,
  },
  {
    path: RouteNames.GAME_SESSION,
    element: <SessionGame />,
  },
  /**
   * **********************
   * ROUTES GAME ADMIN
   * **********************
   */
  {
    path: `${RouteNames.ADMIN_LOBBY}/:token`,
    element: <AdminLobby />,
  },
  {
    path: RouteNames.ADMIN_SESSION,
    element: <AdminSession />,
  },
  {
    path: RouteNames.ADMIN_SPECTATOR,
    element: <AdminSpectator />,
  },
  /**
   * **********************
   * ROUTES MIXTES
   * **********************
   */
  {
    path: RouteNames.GAME_LEADERBOARD,
    element: <Leaderboard />,
  },
];

export default routes;
