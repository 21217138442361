import * as React from 'react';
import './in-game-player-card.css';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import BackHandIcon from '@mui/icons-material/BackHand';
import MenuIcon from '@mui/icons-material/Menu';

import CustomButton from '../custom-button';
import Dropdown from '../dropdown';

import { useTimer } from '../../hooks/useTimer';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export default function InGamePlayerCard(props) {
  const [timer, resetTimer] = useTimer();
  const scoreRef = React.useRef();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const adminControls = [
    {
      name: 'Exclure',
      action: props.onExclude,
    }
  ];

  React.useEffect(() => {
    if (!props.showControls) return;

    // Reset timer
    resetTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isBuzzed]);

  React.useEffect(() => {
    if (!props?.score || !scoreRef || !scoreRef.current) return;

    scoreRef.current.animate(
      [
        { transform: "scale(1)" },
        { transform: "scale(1.75)" },
        { transform: "scale(1)" },
      ],
      {
        duration: 450,
        iterations: 1,
      },
    );
  }, [props.score]);

  /* Fonctions de gestion de l'ouverture du menu de controles */
  const toggleDropdown = React.useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, [setIsDropdownOpen]);

  const closeDropdown = () => {
    if (!isDropdownOpen) return;

    setIsDropdownOpen(false);
  };

  const refDropdownIcon = useOutsideClick(closeDropdown);

  return (
    <div className="in-game-player-card">
      <div
        className="container-score"
        style={{
          ...(props.isBuzzed &&
            {
              borderWidth: '6px 6px 0px 6px',
              borderStyle: 'solid',
              borderColor: 'var(--green)',
            }
          ),
        }}
      >
        {props.showControls && (
          <div className="container-buttons-score">
            <button type="button" onClick={props.playerAddPoint}>+1</button>
            <button type="button" onClick={props.playerSubtractPoint}>-1</button>
          </div>
        )}

        <div className="container-text-score">
          <p ref={scoreRef}>{props?.score || 0} pts</p>
        </div>

        {props.showControls && (
          <>
            <button className="container-button-menu-controls" onClick={toggleDropdown} ref={refDropdownIcon}>
              <MenuIcon sx={{ fontSize: 16 }} />
            </button>
            <Dropdown items={adminControls} isOpen={isDropdownOpen} isBuzzed={props.isBuzzed} />
          </>
        )}
      </div>
      <p
        style={{
          fontFamily: props.isMe ? 'var(--font-family-bold)' : 'var(--font-family-regular)',
          ...(props.isBuzzed &&
            {
              borderWidth: `0px 6px ${props.showControls ? '0' : '6'}px 6px`,
              borderStyle: 'solid',
              borderColor: 'var(--green)',
              borderRadius: props.showControls ? '0px' : '0px 0px 8px 8px',
            }
          ),
        }}
      >
        {props?.content || '-'}
      </p>

      {props.showControls && (
        <div
          id="container-controls"
          style={{
            ...(props.isBuzzed &&
              {
                padding: '6px 0px',
                borderWidth: '0px 6px 6px 6px',
                borderStyle: 'solid',
                borderColor: 'var(--green)',
              }
            ),
          }}
        >
          {props.isBuzzed && (
            <>
              <p>Réponse :</p>
              <div id="container-respond">
                <CustomButton
                  label=""
                  onClick={props.onGoodAnswer}
                  rightIcon={<CheckIcon sx={{ fontSize: 16 }}
                />} />
                <CustomButton
                  label=""
                  onClick={props.onBadAnswer}
                  rightIcon={<ClearIcon sx={{ fontSize: 16 }}
                  secondary
                />} />
              </div>
            </>
          )}

          {props.showControls && props.isBuzzed && (
            <div className="timer-buzz">
              <BackHandIcon sx={{ fontSize: 20 }} />

              <p>{timer}s</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
