import './dropdown.css';

export default function Dropdown(props) {
  return (
    <ul
      className={`dropdown ${props.isOpen ? "shown" : ""}`}
      style={{
        ...(props.isBuzzed &&
          {
            left: '105%',
          }
        ),
      }}
    >
      {props.items.map((item, index) => (
        <li key={index} className="dropdown-item">
          <button onClick={item.action}>{item.name}</button>
        </li>
      ))}
    </ul>
  );
}
