import React from 'react';

import './instruction-card.css';

export default function InstructionCard(props) {
  return (
    <div className="instruction-card">
      <h2>{props.title}</h2>

      <p>{props.content}</p>
    </div>
  );
}
