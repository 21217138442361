import * as React from 'react';
import './proposition-bloc.css';

export default function PropositionBloc(props) {
  return (
    <section
      id="container-proposition-bloc"
      style={{
        backgroundColor: props.isResponse ? 'var(--green)' : 'var(--card-background)',
      }}
    >
      <p
        style={{
          fontFamily: props.isResponse ? 'var(--font-family-bold)' : 'var(--font-family-regular)',
        }}
      >
        {props.label}
      </p>
    </section>
  );
}
