import { Link } from 'react-router-dom';

import './stroke-link.css';

export default function StrokeLink(props) {
  return (
    <Link
      className="stroke-link"
      to={props.to}
      onClick={props.onClick}
    >
      {props.label}
    </Link>
  );
}
