import './custom-checkbox-card.css';

export default function CustomCheckboxCard(props) {
  return (
    <button
      className={`container-custom-checkbox-card ${props.isChecked ? 'checked' : ''}`}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </button>
  );
}
