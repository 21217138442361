import './privacy.css';

export default function Privacy() {
  return (
    <div className='container-privacy'>
      <h1>Politique de confidentialité</h1>

      <h2>PRÉAMBULE</h2>
      <p>
        Cette politique de confidentialité s'applique au site : Double Z
      </p>
      <p>
        La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :
      </p>
      <p>
        La manière dont sont collectées et traitées leurs données à caractère personnel. Les droits des utilisateurs concernant ces données. Qui est responsable du traitement des données à caractère personnel collectées et traitées. À qui ces données sont transmises. Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après : https://www.doublez.io/conditions-generales
      </p>

      <h2>PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h2>
      <p>
        Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :
      </p>
      <p>
        Licéité, loyauté et transparence.
      </p>
      <p>
        Finalités limitées.
      </p>
      <p>
        Minimisation de la collecte et du traitement des données.
      </p>
      <p>
        Conservation des données réduites dans le temps.
      </p>
      <p>
        Intégrité et confidentialité des données collectées et traitées.
      </p>
      <p>
        Afin d'être licites, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions énumérées dans l'article 6 du règlement européen 2016/679.
      </p>

      <h2>DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h2>
      <p>
        Les données à caractère personnel collectées sur le site Double Z sont les suivantes :
      </p>
      <p>
        - Adresse email
      </p>
      <p>
        Ces données sont collectées lorsque l'utilisateur s'inscrit sur le site.
      </p>
      <p>
        La collecte et le traitement des données répondent à la finalité d'identification de l'utilisateur
      </p>
      <p>
        Les traitements de données effectués sont fondés sur les bases du consentement de l'utilisateur
      </p>
      <p>
        Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers et ne sont traitées que par l'éditeur du site.
      </p>
      <p>
        Le site Double Z est hébergé par : Digital Ocean
      </p>
      <p>
        Les données collectées et traitées par le site sont exclusivement hébergées par: Clever Cloud
      </p>

      <h2>RESPONSABLE DU TRAITEMENT DES DONNÉES</h2>
      <p>
        Le responsable du traitement des données à caractère personnel peut être contacté de la manière suivante : contact@studiobleumoutarde.com
      </p>
      <p>
        Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.
      </p>
      <p>
        Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.
      </p>
      <p>
        Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.
      </p>
      <p>
        De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.
      </p>

      <h2>DROITS DE L'UTILISATEUR</h2>
      <p>
        Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.
      </p>
      <p>
        Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.
      </p>
      <p>
        Droit d'accès, de rectification et droit à l'effacement. L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :
      </p>
      <p>
        L'utilisateur doit envoyer un email à l'adresse contact@studiobleumoutarde.com
      </p>
      <p>
        S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la procédure suivante :
      </p>
      <p>
        Se connecter à son espace, naviguer sur la page "Mon compte" pour cliquer sur le bouton de suppression du compte
      </p>
      <p>
        L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.
      </p>
      <p>
        Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :
      </p>
      <p>
        L'utilisateur doit envoyer un email à l'adresse contact@studiobleumoutarde.com
      </p>
      <p>
        Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.
      </p>
      <p>
        Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge compétent.
      </p>

      <h2>CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
      <p>
        La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée : https://www.doublez.io/politique-de-confidentialite
      </p>
      <p>
        L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.
      </p>
      <p>
        Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.
      </p>
      <p>
        Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 01/02/2024.
      </p>
    </div>
  );
}
