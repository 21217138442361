import * as React from 'react';
import './custom-input.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function CustomInput(props) {
  const [localType, setLocalType] = React.useState(props.type || 'text');

  const changeValue = (event) => {
    if (!props.onChange) return;
  
    props.onChange(event.target.value, event.target.dataset);
  };

  const triggerVisibility = () => {
    if (props.type !== 'password') return;

    if (localType === 'text') {
      setLocalType('password');
    } else {
      setLocalType('text');
    }
  };

  return (
    <div className="container-custom-input">
      <input
        className="custom-input"
        type={localType}
        style={{ ...props.style }}
        value={props.value}
        onChange={changeValue}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        minLength={props.minLength}
        maxLength={props.maxLength}
        {...props?.customAttributes}
        autoFocus={props.autoFocus}
      />

      {props.type === 'password' && (
        <button onClick={triggerVisibility} type="button">
          {localType === 'password'
            ? (
              <VisibilityIcon sx={{ fontSize: 22 }} />
            )
            : (
              <VisibilityOffIcon sx={{ fontSize: 22 }} />
            )
          }
        </button>
      )}
    </div>
  );
}
