import { useNavigate, useSearchParams } from 'react-router-dom';
import './checkout.css';

import CustomTextButton from '../../components/custom-text-button';

export default function Checkout() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const goToHome = () => {
    navigate('..');
  };

  const isCheckoutSuccess = searchParams.get('success') && searchParams.get('success') === 'true';

  return (
    <div className='container-checkout'>
      <h1>Votre achat {isCheckoutSuccess ? 'a bien été pris en compte !' : 'a échoué !'}</h1>

      {isCheckoutSuccess ? (
        <p>Merci pour votre achat, vos nouvelles fonctionnalités seront disponibles sous peu</p>
      ) : (
        <p>Votre achat semble avoir échoué, veuillez réessayer ou contactez le support : contact@studiobleumoutarde.com</p>
      )}

      <CustomTextButton label="Revenir à l'accueil" onClick={goToHome} />
    </div>
  );
}
