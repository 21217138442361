import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './admin-lobby.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import apiQuizzes from '../../../services/api/quiz';

import * as socket from '../../../services/websocket';
import PlayerCard from '../../../components/player-card';
import CustomLabel from '../../../components/custom-label';
import CustomButton from '../../../components/custom-button';

import { ActionBack } from '../../../enums/action-back.enum';
import { ActionAnonymous } from '../../../enums/action-anonymous.enum';
import { ActionAdmin } from '../../../enums/action-admin.enum';

import logo from '../../../assets/logo.png';
import RouteNames from '../../../route-names';

export default function AdminLobby() {
  const navigate = useNavigate();
  let params = useParams();

  const [quizz, setQuizz] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [isStartingQuizz, setIsStartingQuizz] = React.useState(false);

  React.useEffect(() => {
    // Register admin auprès du serveur ws
    register();

    // Récupérer les infos de la Game
    getQuizz();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizz = async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByGameToken(params?.token);

      setQuizz(lightQuizz);
    } catch (err) {
      toast.error('Impossible de récupérer les infos du quiz');
    }
  };

  const handleMesagesFromWsServer = (message) => {
    if (message?.action === ActionBack.BACK_PLAYER_LIST) {
      // Rafraichir liste des joueurs
      setPlayers(message.players);
    } else if (message?.action === ActionBack.BACK_START_QUIZZ) {
      // Rediriger vers l'ecran de jeu admin
      navigate(RouteNames.ADMIN_SESSION);
    } else if (message?.action === ActionBack.BACK_REGISTER_ADMIN) {
      localStorage.setItem('GAME_TOKEN', message?.token);
      localStorage.setItem('ADMIN_ID', message?.adminId);

      refreshPlayerList();
    }
  };

  const register = () => {
    // On supprime le potentiel "GAME_TOKEN" en ls pour ne pas se reconnecter avec
    localStorage.removeItem('GAME_TOKEN');
    localStorage.removeItem('ADMIN_ID');

    // Connexion au serveur ws
    socket.init(connectToWs);
  };

  const connectToWs = () => {
    socket.setOnMessage(
      handleMesagesFromWsServer,
      () => {
        socket.register({
          action: ActionAnonymous.REGISTER_ADMIN,
          token: params?.token,
        });
      },
      true, // signale que l'on veut se connecter pour un register
    );
  };

  const refreshPlayerList = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.PLAYER_LIST,
    });
  }, []);

  const startQuizz = React.useCallback(() => {
    setIsStartingQuizz(true);

    socket.sendMessage({
      action: ActionAdmin.START_QUIZZ,
    });
  }, []);

  const excludePlayer = React.useCallback((playerId, username) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_EXCLUDE,
      playerId,
    });

    toast.success(`Joueur "${username}" a été exclu de la partie !`);
  }, []);

  return (
    <div className="container-admin-lobby">
      <Link className="container-logo" to="/" target="_blank">
        <img className="logo" src={logo} />
      </Link>

      <h1 className="title">{quizz?.label}</h1>

      <div className="container-content">
        <CustomLabel label="Participants connectés" />

        {players.map((player) => (
          <div className="player-line" key={player.playerId}>
            <PlayerCard
              key={player.playerId}
              content={player.username}
              exclude={() => excludePlayer(player.playerId, player.username)}
            />
          </div>
        ))}
      </div>

      <p className="indication">Lancez la session lorsque tous les joueurs sont connectés</p>

      <CustomButton label="Lancer la session" onClick={startQuizz} loading={isStartingQuizz} />
    </div>
  );
}
