import * as React from 'react';
import './detail.css';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import * as dayjsFrench from 'dayjs/locale/fr';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

import CustomButton from '../../../../components/custom-button';
import CustomTextButton from '../../../../components/custom-text-button';
import { SortableList } from '../components/sortable-list';
import QuestionBloc from '../components/question-bloc';
import Modal from '../../../../components/modal/modal';
import CustomInput from '../../../../components/custom-input';

import apiQuizzes from '../../../../services/api/quiz';
import apiQuestions from '../../../../services/api/question';
import * as apiUser from '../../../../services/api/user';
import Loader from '../../../../components/loader';
import RouteNames from '../../../../route-names';

export default function DetailQuizBackOffice() {
  dayjs.extend(localizedFormat);
  dayjs.locale(dayjsFrench);

  const navigate = useNavigate();
  let params = useParams();

  const [isInitialLoadFinished, setIsInitialLoadFinished] = React.useState(false);
  const [quizz, setQuizz] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [quizzLinks, setQuizzLinks] = React.useState(null);
  const [isStartingQuizz, setIsStartingQuizz] = React.useState(false);
  const [isEndingQuizz, setIsEndingQuizz] = React.useState(false);
  const [subscription, setSubscription] = React.useState(null);

  const [isLinkPlayerShowed, setIsLinkPlayerShowed] = React.useState(false);
  const [isLinkAdminShowed, setIsLinkAdminShowed] = React.useState(false);

  const [isModalDeleteQuizzVisible, setIsModalDeleteQuizzVisible] = React.useState(false);

  React.useEffect(() => {
    // Récupération du quiz
    getQuizz();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (quizz && questions.length <= 0) {
      // Récupération des questions du quiz
      getQuestions();
    }
  }, [quizz]);

  React.useEffect(() => {
    if (quizz && !subscription) {
      // Récupération de l'abonnement de l'utilisateur
      getCurrentSubscription();
    }
  }, [quizz]);

  const getCurrentSubscription = async () => {
    try {
      const sub = await apiUser.getCurrentSubscription();

      setSubscription(sub);
    } catch {
      //
    }
  };

  const getQuizz = async () => {
    try {
      const quizz = await apiQuizzes.getQuizz(params?.quizzId);

      setQuizz(quizz);

      // Définition des liens d'une Game déjà ouverte
      if (quizz?.playerLink && quizz?.adminLink) {
        setQuizzLinks({
          playerLink: quizz.playerLink,
          adminLink: quizz.adminLink,
        });
      }
    } catch {
      setQuizz(null);
    } finally {
      setIsInitialLoadFinished(true);
    }
  };

  const getQuestions = async () => {
    if (!quizz) return;

    try {
      const tmpQuestions = await apiQuestions.getQuestions(params?.quizzId);

      setQuestions(tmpQuestions);
    } catch {
      setQuestions([]);
    }
  };

  const startQuizz = async () => {
    setIsStartingQuizz(true);

    try {
      const tmpLinks = await apiQuizzes.startQuizz(params?.quizzId);

      toast.success('Session démarrée !');

      setQuizzLinks(tmpLinks);
    } catch {
      toast.error('Le quiz n\'a pas pu être démarré. Réessayez plus tard.');
    } finally {
      setIsStartingQuizz(false);
    }
  };

  const endQuizz = async () => {
    setIsEndingQuizz(true);

    try {
      await apiQuizzes.endQuizz(params?.quizzId);

      toast.success('Session fermée');

      setQuizzLinks(null);
    } catch {
      toast.error('La session n\'a pas pu être fermée.');
    } finally {
      setIsEndingQuizz(false);
    }
  };

  const updateQuizzLabel = (label) => {
    if (label && label.length > 128) {
      toast.error('Le nom du quiz ne doit pas dépasser 128 caractères');
      return;
    }

    setQuizz({ ...quizz, label });
  };

  const updateQuizz = async () => {
    try {
      await apiQuizzes.updateQuizz(params?.quizzId, { label: quizz.label });

      toast.success('Nom du quiz mis à jour');

      // Rafraichir les infos du quizz
      getQuizz();
    } catch {
      toast.error('Mise à jour impossible');
    }
  };

  const deleteQuizz = async () => {
    try {
      await apiQuizzes.deleteQuizz(params?.quizzId);

      toast.success('Le quiz a été supprimé');

      // Rediriger vers l'ecran de liste des quiz
      navigate(RouteNames.QUIZZES);
    } catch {
      toast.error('Suppression impossible');
    }
  };

  const createQuestion = () => {
    // Rediriger vers l'ecran de création de question
    navigate(`creer-question`, { relative: 'path' });
  };

  const createRandomQuestions = () => {
    // Rediriger vers l'écran de création de questions aléatoires
    navigate(`creer-question-aleatoire`, { relative: 'path' });
  }

  const copyToClipboard = (text) => {
    if (!text) return;

    navigator.clipboard.writeText(text);
  };

  const editQuestion = (questionId) => {
    // Rediriger vers l'ecran d'édition de question
    navigate(`editer-question/${questionId}`, { relative: 'path' });
  };

  const deleteQuestion = async (questionId) => {
    try {
      // Suppression de la question en BDD
      await apiQuestions.deleteQuestion(params.quizzId, questionId);

      toast.success('La question a été supprimée');
    } catch {
      toast.error('La question n\'a pas pu être supprimée');
      return;
    }

    // Mise à jour des questions en local
    getQuestions();
  };

  const onSort = async (questionId, oldIndex, newIndex) => {
    // On fait une copie des questions pour pouvoir revenir en arrière
    const questionsCopy = questions.slice();

    // On ordonne les questions comme si la requête avait réussie (pour éviter du flicker au niveau du texte de la question quand le drag end)
    const newItems = questions.slice();
    newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
    setQuestions(newItems);

    try {
      // Modification des positions des questions en BDD
      await apiQuestions.updateQuestion(quizz.quizzId, questionId, { position: newIndex + 1 });

      getQuestions();
    } catch {
      toast.error('La question n\'a pas pu être ordonnée');

      // Si la maj de la position échoue alors on revient à l'état avant la maj
      setQuestions(questionsCopy);

      return;
    }
  };

  const copyLink = (link) => {
    copyToClipboard(link);

    toast.success('Lien copié !');
  };

  const goBack = () => {
    navigate('..', { relative: 'path' });
  };

  const triggerVisibilityLinkAdmin = () => {
    setIsLinkAdminShowed((prev) => !prev);
  };

  const triggerVisibilityLinkPlayer = () => {
    setIsLinkPlayerShowed((prev) => !prev);
  };

  const openModal = () => {
    setIsModalDeleteQuizzVisible(true);
  };

  const closeModal = () => {
    setIsModalDeleteQuizzVisible(false);
  };

  const nbAvailableQuestions = Math.max(Number(subscription?.maxNbQuestions) - (questions?.length || 0), 0);

  return (
    <div className="container-quizzdetails">
      {!isInitialLoadFinished && (
        <section id="container-loader">
          <Loader loading={true} />
        </section>
      )}

      {isInitialLoadFinished && !quizz && (
        <section className="container-no-quizz-found">
          <h1>Oops!</h1>
    
          <p>Le quiz demandé n'a pas été trouvé !</p>
    
          <CustomTextButton label="Revenir à la liste des quiz" onClick={goBack} />
        </section>
      )}

      {isInitialLoadFinished && !!quizz && (
        <>
          <section className="container-metadata">
            <div className="container-title">
              <h1>Quiz</h1>

              <CustomButton label="Supprimer" onClick={openModal} secondary />
            </div>

            <CustomInput
              value={quizz.label}
              onChange={updateQuizzLabel}
              placeholder="ex : Quiz sur l'histoire de l'art"
              maxLength={128}
            />

            <CustomButton label="Modifier le nom" onClick={updateQuizz} />
          </section>

          <section className="container-links">
            <p className="title-links">Session</p>

            {!quizzLinks?.playerLink && !quizzLinks?.adminLink && (
              <>
                <p className="note">Cliquez sur "Ouvrir une session" pour obtenir le lien pour inviter les participants</p>

                <CustomButton label="Ouvrir une session" onClick={startQuizz} loading={isStartingQuizz} />
              </>
            )}

            {quizzLinks?.playerLink && (
              <div className="container-bloc-link">
                <p>Lien joueur <span className="note-links">(Envoyez ce lien aux personnes que vous souhaitez inviter à la session)</span></p>
                
                <div>
                  <p>{isLinkPlayerShowed ? quizzLinks.playerLink : '•••••••••••••••••••••••••••••'}</p>

                  <div className="container-icons">
                    <button onClick={triggerVisibilityLinkPlayer} type="button">
                      {isLinkPlayerShowed ? (
                        <VisibilityOffIcon sx={{ fontSize: 20 }} />
                      ) : (
                        <VisibilityIcon sx={{ fontSize: 20 }} />
                      )}
                    </button>

                    <ContentCopyIcon onClick={() => copyLink(quizzLinks.playerLink)} sx={{ fontSize: 20 }} />
                  </div>
                </div>
              </div>
            )}
            
            {quizzLinks?.adminLink && (
              <div className="container-bloc-link">
                <p>Lien animateur <span className="note-links">(Ouvrez ce lien dans un nouvel onglet pour animer la session, seul vous doit avoir ce lien. Il ne peut y avoir qu'un seul animateur)</span></p>
                
                <div>
                  <p>{isLinkAdminShowed ? quizzLinks.adminLink : '•••••••••••••••••••••••••••••'}</p>

                  <div className="container-icons">
                    <button onClick={triggerVisibilityLinkAdmin} type="button">
                      {isLinkAdminShowed ? (
                        <VisibilityOffIcon sx={{ fontSize: 20 }} />
                      ) : (
                        <VisibilityIcon sx={{ fontSize: 20 }} />
                      )}
                    </button>

                    <ContentCopyIcon onClick={() => copyLink(quizzLinks.adminLink)} sx={{ fontSize: 20 }} />
                  </div>
                </div>
              </div>
            )}

            {quizzLinks?.playerLink && quizzLinks?.adminLink && (
              <CustomButton label="Fermer la session" onClick={endQuizz} loading={isEndingQuizz} secondary />
            )}
          </section>

          <section className="container-add-question">
            <CustomButton
              label="Ajouter une question manuelle"
              onClick={createQuestion}
              leftIcon={<AddIcon sx={{ fontSize: 20 }} />}
              disabled={!nbAvailableQuestions || nbAvailableQuestions <= 0}
            />

            <CustomButton
              label="Ajouter des questions aléatoires"
              onClick={createRandomQuestions}
              leftIcon={<AddIcon sx={{ fontSize: 20 }} />}
              disabled={!nbAvailableQuestions || nbAvailableQuestions <= 0}
            />
          </section>

          <section className="container-questions">
            {questions?.length > 0 ? (
              <SortableList
                items={questions}
                getItemId={(question) => question.questionId}
                renderItem={(props) => {
                  return (
                    <QuestionBloc
                      props={props}
                      action={() => editQuestion(props.item.questionId)}
                      actionSecondary={() => deleteQuestion(props.item.questionId)}
                    />
                  );
                }}
                onSort={onSort}
              />
            ) : (
              <>
                <p className="text-no-questions">Commencez à créer vos questions manuellement ou sélectionnez aléatoirement des questions depuis notre base de questions !</p>
              </>
            )}
          </section>

          {questions.length >= 3 && (
            <section className="container-add-question">
              <CustomButton
                label="Ajouter une question manuelle"
                onClick={createQuestion}
                leftIcon={<AddIcon sx={{ fontSize: 20 }} />}
                disabled={!nbAvailableQuestions || nbAvailableQuestions <= 0}
              />

              <CustomButton
                label="Ajouter des questions aléatoires"
                onClick={createRandomQuestions}
                leftIcon={<AddIcon sx={{ fontSize: 20 }} />}
                disabled={!nbAvailableQuestions || nbAvailableQuestions <= 0}
              />
            </section>
          )}
        </>
      )}

      {isModalDeleteQuizzVisible && (
        <Modal isOpen={isModalDeleteQuizzVisible} content="Êtes-vous sûr de vouloir supprimer ce quiz ?" onConfirm={deleteQuizz} onClose={closeModal} />
      )}
    </div>
  );
}
