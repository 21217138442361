import CustomTextButton from '../../custom-text-button';
import CustomTextLink from '../../custom-text-link';

import RouteNames from '../../../route-names';

export default function ColumnHeader(props) {
  return (
    <div className="container-column-header">
      {!props?.isConnected && (
        <div className="contentRightContainer">
          <CustomTextLink to={RouteNames.PRICING} label="Tarification" onClick={props.navigateToPricing} />

          <CustomTextLink to={RouteNames.LOGIN} label="Connexion" onClick={props.navigateToLogin} />

          <CustomTextLink to={RouteNames.REGISTER} label="S'inscrire" onClick={props.navigateToRegister} />
        </div>
      )}

      {props?.isConnected && (
        <div className="contentRightContainer">
          <CustomTextLink to={RouteNames.PRICING} label="Tarification" onClick={props.navigateToPricing} />

          <CustomTextLink to={RouteNames.QUIZZES} label="Mes quiz" onClick={props.navigateToQuizzes} />

          <CustomTextLink to={RouteNames.ACCOUNT} label="Mon compte" onClick={props.navigateToAccount} />

          <CustomTextButton label="Se déconnecter" onClick={props.disconnect} />
        </div>
      )}
    </div>
  );
}
