import * as React from 'react';
import './list.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import apiQuizzes from '../../../../services/api/quiz';
import * as apiUser from '../../../../services/api/user';

import QuizzCard from '../components/quizz-card';
import CustomButton from '../../../../components/custom-button';
import Loader from '../../../../components/loader';
import RouteNames from '../../../../route-names';

export default function ListQuizBackOffice() {
  const navigate = useNavigate();

  const [quizzes, setQuizzes] = React.useState(null);
  const [isCreating, setIsCreating] = React.useState(false);
  const [subscription, setSubscription] = React.useState(null);

  React.useEffect(() => {
    // Récupération des quiz de l'utilisateur
    getQuizzes();

    // Récupération de l'abonnement de l'utilisateur
    getCurrentSubscription();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizzes = async () => {
    try {
      const quizzes = await apiQuizzes.getQuizzes(true);

      setQuizzes(quizzes);
    } catch {
      setQuizzes([]);
    }
  };

  const getCurrentSubscription = async () => {
    try {
      const sub = await apiUser.getCurrentSubscription();

      setSubscription(sub);
    } catch {
      //
    }
  };

  const goToQuizz = (quizzId) => {
    navigate(`${RouteNames.QUIZZES}/${quizzId}`);
  };

  const goToCreateQuizz = async () => {
    setIsCreating(true);

    // Créer un quiz
    let newQuizz = null;
    try {
      newQuizz = await apiQuizzes.createQuizz();
    } catch {
      toast.error('Le quiz n\'a pas pu être créé. Réessayez plus tard.');

      setIsCreating(false);
      return;
    }

    // Rediriger vers la page de détail
    if (newQuizz?.quizzId) {
      toast.success('Un quiz a été créé');

      navigate(`${RouteNames.QUIZZES}/${newQuizz.quizzId}`);
    }

    setIsCreating(false);
  };

  const nbMaxQuizz = Number(subscription?.maxNbQuizz);
  const nbAvailableQuizzes = Math.max(nbMaxQuizz - (quizzes?.length || 0), 0);

  return (
    <div className="container-quizzlist">
      <section className="container-list-quizzes">
        <div className="container-title">
          <h1 className="title">Mes quiz<span className="title-count-quizzes">{quizzes?.length && nbMaxQuizz ? `(${quizzes?.length || 0}/${nbMaxQuizz})` : ''}</span></h1>

          <CustomButton
            label="Créer"
            onClick={goToCreateQuizz}
            disabled={!nbMaxQuizz || nbAvailableQuizzes <= 0}
            loading={isCreating}
          />
        </div>

        {!quizzes && (
          <section id="container-loader">
            <Loader loading={true} />
          </section>
        )}

        {quizzes && quizzes.length > 0 && (
          <div className="container-cards">
            {quizzes.map((quizz) => (
              <QuizzCard
                key={quizz.quizzId}
                label={quizz.label}
                createdAt={quizz.createdAt}
                onClick={() => goToQuizz(quizz.quizzId)}
              />
            ))}
          </div>
        )}

        {quizzes && quizzes.length <= 0 && (
          <section id="container-no-quizzes">
            <p>Créez un quiz pour commencer à choisir les questions et inviter des joueurs</p>
          </section>
        )}
      </section>
    </div>
  );
}
