import { Link } from "react-router-dom";

import './notice.css';
import RouteNames from "../../route-names";

export default function Notice() {
  return (
    <div className='container-notice'>
      <h1>Mentions légales</h1>

      <h2>Édition du site</h2>
      <p>
        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet https://www.doublez.io/ l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:
      </p>
      <p>
        Propriétaire du site : StudioBleuMoutarde (Nom commercial) - LLORCA FREDERIC EI (Dénomination sociale) - SIREN : 983104928
      </p>
      <p>
        Contact : contact@studiobleumoutarde.com
      </p>
      <p>
        Hébergeur : <a href="https://www.digitalocean.com/" target="_blank">Digital Ocean</a>
      </p>

      <h2>Limitations de responsabilité</h2>
      <p>
        StudioBleuMoutarde ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site https://www.doublez.io/.
      </p>
      <p>
        StudioBleuMoutarde décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur https://www.doublez.io/.
      </p>
      <p>
        StudioBleuMoutarde s’engage à sécuriser au mieux le site https://www.doublez.io/, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.
      </p>

      <h2>CNIL et gestion des données personnelles</h2>
      <p>
        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site https://www.doublez.io/ dispose d’un droit d’accès, de modification et de suppression des informations collectées. Pour exercer ce droit, envoyez un message à : - contact@studiobleumoutarde.com
      </p>
      <p>
        Pour plus d'informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire...), lisez notre <Link to={RouteNames.PRIVACY}>Politique de confidentialité</Link>
      </p>

      <h2>Liens hypertextes</h2>
      <p>
        Le site https://www.doublez.io/ contient des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers https://www.doublez.io/
      </p>

      <h2>Droit applicable et attribution de juridiction</h2>
      <p>
        Tout litige en relation avec l’utilisation du site https://www.doublez.io/ est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents
      </p>
    </div>
  );
}
