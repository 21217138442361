export const ActionAdmin = {
  START_QUIZZ: 'START_QUIZZ',
  PLAYER_LIST: 'PLAYER_LIST',

  ADMIN_PREV_Q: 'ADMIN_PREV_Q',
  ADMIN_NEXT_Q: 'ADMIN_NEXT_Q',
  ADMIN_Q_TITLE: 'ADMIN_Q_TITLE',
  ADMIN_Q_IMAGE: 'ADMIN_Q_IMAGE',
  ADMIN_Q_PROP: 'ADMIN_Q_PROP',
  ADMIN_Q_RES: 'ADMIN_Q_RES',
  ADMIN_Q_EXP_RES: 'ADMIN_Q_EXP_RES',
  ADMIN_Q_PLAY: 'ADMIN_Q_PLAY',
  ADMIN_Q_PAUSE: 'ADMIN_Q_PAUSE',
  ADMIN_P_GOOD_ANSWER: 'ADMIN_P_GOOD_ANSWER',
  ADMIN_P_BAD_ANSWER: 'ADMIN_P_BAD_ANSWER',
  ADMIN_P_ADD_POINT: 'ADMIN_P_ADD_POINT',
  ADMIN_P_SUBTRACT_POINT: 'ADMIN_P_SUBTRACT_POINT',
  ADMIN_P_EXCLUDE: 'ADMIN_P_EXCLUDE',

  ADMIN_COLLECT_TEXT_RESPONSES: 'ADMIN_COLLECT_TEXT_RESPONSES',
  ADMIN_DISPLAY_TEXT_RESPONSE: 'ADMIN_DISPLAY_TEXT_RESPONSE',

  ADMIN_COLLECT_DRAWINGS: 'ADMIN_COLLECT_DRAWINGS',
  ADMIN_DISPLAY_DRAWING: 'ADMIN_DISPLAY_DRAWING',
};
