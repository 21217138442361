import * as React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import './leaderboard.css';

import apiQuizzes from '../../../services/api/quiz';
import apiGames from '../../../services/api/game';

import PlayerResult from './components/player-result';

import logo from '../../../assets/logo.png';

export default function Leaderboard() {
  const [quizz, setQuizz] = React.useState(null);
  const [game, setGame] = React.useState(null);

  React.useEffect(() => {
    // Récupérer les infos du Quiz
    getQuizz();

    // Récupérer les infos de la Game
    getGame();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizz = async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByPlayerJwtToken();

      setQuizz(lightQuizz);
    } catch (err) {
      toast.error('Une erreur est survenue. Veuillez recharger la page.');
    }
  };

  const getGame = async () => {
    try {
      const tmpGame = await apiGames.getGame();

      setGame(tmpGame);
    } catch (err) {
      toast.error('Une erreur est survenue. Veuillez recharger la page.');
    }
  };

  // On tri les joueurs par score
  const sortedPlayers = game?.players ? game.players.sort((a, b) => b.score - a.score) : [];

  return (
    <div className="container-player-leaderboard">
      <Link className="container-logo" to="/" target="_blank">
        <img src={logo} />
      </Link>

      <h1>{quizz?.label}</h1>

      <p className="admin-username"><span>Animé par :</span> {game?.admin?.username}</p>

      <div className="container-list-players">
        {sortedPlayers.map((player, index) => (
          <PlayerResult
            key={player.playerId}
            username={player.username}
            position={index + 1}
            score={player.score}
          />
        ))}
      </div>
    </div>
  );
}
