import * as React from 'react';
import './player-card.css';

export default function PlayerCard(props) {
  return (
    <div
      className="player-card"
      style={{
        backgroundColor: props.isMe ? 'var(--primary)' : 'var(--background-darker)',
      }}
    >
      <p>{props?.content}</p>

      {props.exclude && (
        <button
          type="button"
          onClick={props.exclude}>
          Exclure
        </button>
      )}
    </div>
  );
}
