import * as React from 'react';
import './modal.css';

import CustomButton from '../custom-button';

export default function Modal(props) {
  const [isOpen, setIsOpen] = React.useState(props.isOpen);
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  React.useEffect(() => {
    if (!modalRef || !modalRef.current) return;

    if (isOpen) {
      modalRef.current.showModal();
    } else {
      modalRef.current.close();
    }
  }, [isOpen]);

  const close = () => {
    if (props.onClose) {
      props.onClose();
    }

    setIsOpen(false);
  };

  const confirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }

    setIsOpen(false);
  };

  const clickOutside = (event) => {
    if (event.target.tagName === 'DIALOG') close();
  };

  return (
    <dialog className="modal" ref={modalRef} onClick={clickOutside}>
      <div className="container-content">
        <p>{props?.content}</p>

        <div className="container-buttons">
          <CustomButton
            label="Annuler"
            onClick={close}
          />

          <CustomButton
            label="Supprimer"
            onClick={confirm}
            secondary
          />
        </div>
      </div>
    </dialog>
  );
}
