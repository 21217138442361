import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './admin-session.css';
import { toast } from 'react-toastify';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import apiQuizzes from '../../../services/api/quiz';
import * as socket from '../../../services/websocket';

import InGamePlayerCard from '../../../components/in-game-player-card';
import CustomButton from '../../../components/custom-button';
import PropositionBloc from '../../player/components/proposition-bloc';
import AudioPlayer from './components/audio-player';
import NotStarted from './components/not-started';
import PlayerTextResponse from '../../../components/player-text-reponse';
import PlayerDrawing from '../../../components/player-drawing';

import { useTimer } from '../../../hooks/useTimer';

import { ActionBack } from '../../../enums/action-back.enum';
import { ActionAdmin } from '../../../enums/action-admin.enum';

import selfBuzz from '../../../assets/self-buzz.mp3';
import RouteNames from '../../../route-names';

let isBuzzVolumeActive;
let setIsBuzzVolumeActive;

let playerTextResponses;
let setPlayerTextResponses;

let playerDrawings;
let setPlayerDrawings;

let broadcastChannelSpectatorMode = null;

export default function AdminSession() {
  const navigate = useNavigate();

  const [quizz, setQuizz] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [prevQLoading, setPrevQLoading] = React.useState(false);
  const [nextQLoading, setNextQLoading] = React.useState(false);

  const [question, dispatchQuestion] = React.useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      title: '',
      theme: '',
      propositions: null,
      response: '',
      points: null,
    },
  );
  const [indexCurrentQuestion, setIndexCurrentQuestion] = React.useState(null);
  const [nbTotalQuestions, setNbTotalQuestions] = React.useState(null);
  const [playerBuzzed, setPlayerBuzzed] = React.useState([]);

  const [timer, resetTimer] = useTimer();

  let rateLimitWSCallsTimer = null;
  const [isRateLimitTimerActive, setIsRateLimitTimerActive] = React.useState(false);

  [isBuzzVolumeActive, setIsBuzzVolumeActive] = React.useState(true);
  const audioBuzzRef = React.useRef(null);

  [playerTextResponses, setPlayerTextResponses] = React.useState([]);
  [playerDrawings, setPlayerDrawings] = React.useState([]);

  // Fonction pour limiter le nombre d'appels que peux faire un admin au serveur WS (1 appel toutes les 750ms)
  const limitRateWSCalls = (func, timeout = 750) => {
    if (rateLimitWSCallsTimer) return;
    setIsRateLimitTimerActive(true);

    func();

    rateLimitWSCallsTimer = setTimeout(() => {
      clearTimeout(rateLimitWSCallsTimer);
      rateLimitWSCallsTimer = null;
      setIsRateLimitTimerActive(false);
    }, timeout);
  };

  React.useEffect(() => {
    // Récupérer les infos de la Game
    getQuizz();

    socket.setOnMessage(handleMesagesFromWsServer, refreshPlayerList);

    // Connexion au Broadcast Channel pour la communication avec le mode spec
    broadcastChannelSpectatorMode = new BroadcastChannel('spectator-mode');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On met à jour la fonction de gestion des messages WS si la liste des joueurs est mise à jour
  // parce que la fonction "handleMesagesFromWsServer" est mise en cache par React avec la valeur
  // de "players" au moment où elle est créée, donc liste vide []
  React.useEffect(() => {
    socket.setOnMessage(handleMesagesFromWsServer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  const getQuizz = React.useCallback(async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByPlayerJwtToken();

      setQuizz(lightQuizz);
    } catch (err) {
      toast.error('Impossible de récupérer les infos du quiz');
    }
  }, []);

  const handleMesagesFromWsServer = (message) => {
    // Envoi des infos à la fenêtre spectateur
    broadcastChannelSpectatorMode.postMessage(message);

    if (message?.action === ActionBack.BACK_PLAYER_LIST) {
      // Rafraichir liste des joueurs
      setPlayers(message.players);
    } else if (message?.action === ActionBack.BACK_Q_COMPLETE) {
      // Stockage des infos de la question
      dispatchQuestion(message.currentQuestion);

      // Stockage des infos meta de la question en cours
      setIndexCurrentQuestion(message.currentQuestionIndex);
      setNbTotalQuestions(message.totalQuestions);

      // Reset des loaders de Q
      setNextQLoading(false);
      setPrevQLoading(false);

      // Reset des réponses texte
      setPlayerTextResponses([]);

      // Reset des dessins
      setPlayerDrawings([]);
    } else if (message?.action === ActionBack.BACK_P_BUZZ) {
      if (isBuzzVolumeActive && audioBuzzRef && audioBuzzRef.current) audioBuzzRef.current.play();

      // Remplis le joueur ayant buzzé
      setPlayerBuzzed(message?.playerId);

      const playerWhoBuzzed = players.find((p) => p.playerId === message?.playerId);

      if (playerWhoBuzzed) {
        // Notification qu'un joueur a buzzé
        toast.success(
          `${playerWhoBuzzed.username} a buzzé !`,
          {
            autoClose: 3000,
            theme: 'colored',
          },
        );
      }
    } else if (message?.action === ActionBack.BACK_P_UNBUZZ) {
      // Libère le buzz
      setPlayerBuzzed('');
    } else if (message?.action === ActionBack.BACK_END_QUIZZ) {
      // Déconnecte le socket
      socket.disconnect();

      // Navigue vers la page leaderboard
      navigate(RouteNames.GAME_LEADERBOARD);
    } else if (message?.action === ActionBack.BACK_SEND_TEXT_RESPONSE) {
      setPlayerTextResponses(() => [
        ...playerTextResponses,
        {
          value: message.value,
          playerId: message.playerId,
          username: message.username,
        },
      ]);
    } else if (message?.action === ActionBack.BACK_SEND_DRAWING) {
      // On cherche si le dessin a déjà été récupéré
      const indexExistingDrawing = playerDrawings.findIndex((d) => d.playerId === message.playerId);

      if (indexExistingDrawing !== -1) {
        // Si le dessin avait déjà été récupéré, on le met à jour
        const updatedDrawingList = playerDrawings.map((d) => {
          if (d.playerId === message.playerId) {
            return {
              value: message.value,
              playerId: message.playerId,
              username: message.username,
            };
          }

          return d;
        });

        setPlayerDrawings(updatedDrawingList);
      } else {
        // Si le dessin n'avait pas encore été récupéré, on l'ajoute à la liste
        setPlayerDrawings(() => [
          ...playerDrawings,
          {
            value: message.value,
            playerId: message.playerId,
            username: message.username,
          },
        ]);
      }
    }
  };

  const refreshPlayerList = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.PLAYER_LIST,
    });
  }, []);

  const prevQuestion = () => {
    setPrevQLoading(true);

    socket.sendMessage({
      action: ActionAdmin.ADMIN_PREV_Q,
    });

    // On réinitialise le timer de la question
    resetTimer();
  };

  const nextQuestion = () => {
    setNextQLoading(true);

    socket.sendMessage({
      action: ActionAdmin.ADMIN_NEXT_Q,
    });

    // On réinitialise le timer de la question
    resetTimer();
  };

  const displayLabel = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_TITLE,
    });

    toast.success('Label envoyé !');
  }, []);
  
  const displayImage = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_IMAGE,
    });

    toast.success('Image envoyée !');
  }, []);

  const displayPropositions = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_PROP,
    });

    toast.success('Propositions envoyés !');
  }, []);

  const displayResponse = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_RES,
    });

    toast.success('Réponse envoyée !');
  }, []);

  const displayExplicitResponse = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_EXP_RES,
    });

    toast.success('Informations additionnelles envoyée !');
  }, []);

  const displayTextToPlayers = (username, value) => {
    const messageToSend = {
      action: ActionAdmin.ADMIN_DISPLAY_TEXT_RESPONSE,
      username,
      value,
    };

    socket.sendMessage(messageToSend);

    toast.success('Réponse texte affichée aux joueurs');

    // Envoi des infos à la fenêtre spectateur
    broadcastChannelSpectatorMode.postMessage(messageToSend);
  };

  const displayDrawingToPlayers = (username, value) => {
    const messageToSend = {
      action: ActionAdmin.ADMIN_DISPLAY_DRAWING,
      username,
      value,
    };

    socket.sendMessage(messageToSend);

    toast.success('Dessin affiché aux joueurs');

    // Envoi des infos à la fenêtre spectateur
    broadcastChannelSpectatorMode.postMessage(messageToSend);
  };

  const displayAudioPlay = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_PLAY,
    });

    toast.success('Audio lancé !');
  }, []);

  const displayAudioPause = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_Q_PAUSE,
    });

    toast.success('Audio mis en pause !');
  }, []);

  const playerGoodAnswer = React.useCallback((playerId, username) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_GOOD_ANSWER,
      playerId,
    });

    toast.success(`Joueur "${username}" a bien répondu !`);
  }, []);

  const playerBadAnswer = React.useCallback((playerId) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_BAD_ANSWER,
      playerId,
    });
  }, []);

  const playerAddPoint = React.useCallback((playerId) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_ADD_POINT,
      playerId,
      value: 1,
    });
  }, []);

  const playerSubtractPoint = React.useCallback((playerId) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_SUBTRACT_POINT,
      playerId,
      value: -1,
    });
  }, []);

  const excludePlayer = React.useCallback((playerId, username) => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_P_EXCLUDE,
      playerId,
    });

    toast.success(`Joueur "${username}" a été exclu de la partie !`);
  }, []);

  const onChangeBuzzVolumeActive = (event) => {
    setIsBuzzVolumeActive(event?.target?.checked);
  };

  const getTextResponses = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_COLLECT_TEXT_RESPONSES,
    });

    toast.success('Récupération des réponses texte...');
  }, []);

  const getDrawings = React.useCallback(() => {
    socket.sendMessage({
      action: ActionAdmin.ADMIN_COLLECT_DRAWINGS,
    });

    toast.success('Récupération des dessins...');
  }, []);

  // Tri DESC des joueurs par score
  const sortedPlayers = React.useMemo(() => players.sort((a, b) => b.score - a.score), [players]);

  const iframeSpectatorMode = React.useMemo(() => (
    <iframe
      id="iframe-spectator"
      title="Section spectateur pour la session en cours"
      src={`${process.env.REACT_APP_FRONT_URL}/jeu/spectateur`}
      sandbox='allow-same-origin allow-scripts'
    />
  ), []);

  const openSpectatorInNewTab = () => {
    window.open(`${process.env.REACT_APP_FRONT_URL}/jeu/spectateur`, '_blank');
  };

  const urlify = (text) => {
    if (!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex)
      .map(part => {
        if (part.match(urlRegex)) {
          return <a href={part} target="_blank" key={part}> {part} </a>;
        }
        return part;
      });
  };

  const creditImage = React.useMemo(() => urlify(question?.image?.credit), [question?.image?.credit]);
  const creditAudio = React.useMemo(() => urlify(question?.audio?.credit), [question?.audio?.credit]);
  const explicitResponse = React.useMemo(() => urlify(question?.explicit_response), [question?.explicit_response]);

  return (
    <div className="container-admin-session">
      <section id="container-left-content">
        <div>
          <h1>{quizz?.label}</h1>

          <div id="player-list">
            {sortedPlayers.map((player) => (
              <InGamePlayerCard
                key={player.playerId}
                content={player.username}
                score={player.score}
                isBuzzed={player.playerId === playerBuzzed}
                showControls
                onGoodAnswer={() => playerGoodAnswer(player.playerId, player.username)}
                onBadAnswer={() => playerBadAnswer(player.playerId)}
                onExclude={() => excludePlayer(player.playerId, player.username)}
                playerAddPoint={() => playerAddPoint(player.playerId)}
                playerSubtractPoint={() => playerSubtractPoint(player.playerId)}
              />
            ))}
          </div>

          <audio ref={audioBuzzRef}>
            <source src={selfBuzz} />
          </audio>

          <div className="container-toggle">
            <p>Son buzzer</p>
            <input type="checkbox" checked={isBuzzVolumeActive} onChange={onChangeBuzzVolumeActive} />
          </div>
        </div>
      </section>

      <section id="container-right-content">
        <div id="container-metadata">
          {!(!indexCurrentQuestion || indexCurrentQuestion <= 1) ? (
            <CustomButton
              label="Question précédente"
              onClick={() => limitRateWSCalls(() => prevQuestion())}
              leftIcon={<ArrowBackIosNewIcon sx={{ fontSize: 20 }} />}
              loading={prevQLoading}
              disabled={!indexCurrentQuestion || indexCurrentQuestion <= 1}
            />
          ) : (
            <span />
          )}

          {!!indexCurrentQuestion && !!nbTotalQuestions && (
            <div className="header-question">
              <p>Question : {indexCurrentQuestion} / {nbTotalQuestions}</p>

              <div className="timer-question">
                <AccessTimeIcon sx={{ fontSize: 20 }} />

                <p>Débuté depuis</p>
                <p>{timer}s</p>
              </div>
            </div>
          )}

          {!!indexCurrentQuestion && !!nbTotalQuestions && indexCurrentQuestion >= nbTotalQuestions ? (
            <CustomButton
            label="Terminer le quiz"
            onClick={() => limitRateWSCalls(() => nextQuestion())}
            loading={nextQLoading}
          />
          ) : (
            <CustomButton
              label="Question suivante"
              onClick={() => limitRateWSCalls(() => nextQuestion())}
              rightIcon={<ArrowForwardIosIcon sx={{ fontSize: 20 }} />}
              loading={nextQLoading}
            />
          )}
        </div>

        <div id="container-elements">
          {!indexCurrentQuestion && !nbTotalQuestions && (
            <NotStarted />
          )}

          {!!indexCurrentQuestion && !!nbTotalQuestions && (
            <>
              {/* CONTEXT */}
              <section className="container-element">
                <div className="left-content">
                  <p><span className="element-label">thème :</span> {question?.theme}</p>
                  <p><span className="element-label">points :</span> {question?.points} pts</p>
                </div>

                <div className="middle-content">
                </div>

                <div className="right-content">
                  <p className="indication">Ces infos ont déjà été envoyées aux joueurs</p>
                </div>
              </section>
              
              {/* TITLE */}
              {question.title && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">label :</span></p>
                  </div>

                  <div className="middle-content">
                    <p>{question.title}</p>
                  </div>

                  <div className="right-content">
                    <button className="action-button" type="button" onClick={() => limitRateWSCalls(() => displayLabel())} disabled={isRateLimitTimerActive}>
                      <PresentToAllIcon sx={{ fontSize: 24 }} />
                      Afficher aux joueurs
                    </button>
                  </div>
                </section>
              )}
              
              {/* AUDIO */}
              {question?.audio?.url && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">audio :</span></p>
                  </div>

                  <div className="middle-content">
                    <AudioPlayer
                      src={question?.audio?.url}
                      onPlay={() => limitRateWSCalls(() => displayAudioPlay())}
                      onPause={() => limitRateWSCalls(() => displayAudioPause())}
                    />

                    <p className="credit">Source : {creditAudio}</p>
                  </div>

                  <div className="right-content">
                    <p className="indication">Utilisez le lecteur pour lancer le son, le mettre en pause et régler le volume (uniquement pour vous, pas pour les joueurs)</p>
                  </div>
                </section>
              )}
              
              {/* IMAGE */}
              {question?.image?.url && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">image :</span></p>
                  </div>

                  <div className="middle-content">
                    <img src={question?.image?.url} alt="prévisualisation" />

                    <p className="credit">Source : {creditImage}</p>
                  </div>

                  <div className="right-content">                    
                    <button className="action-button" type="button" onClick={() => limitRateWSCalls(() => displayImage())} disabled={isRateLimitTimerActive}>
                      <PresentToAllIcon sx={{ fontSize: 24 }} />
                      Afficher aux joueurs
                    </button>
                  </div>
                </section>
              )}
              
              {/* PROPOSITIONS */}
              {question.propositions && question.propositions?.length > 0 && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">propositions :</span></p>
                  </div>

                  <div className="middle-content">
                    {question.propositions && (
                      <div id="element-propositions">
                        {question.propositions.map((prop, i) => (
                          <PropositionBloc
                            key={i}
                            label={prop.label}
                          />
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="right-content">
                    <button className="action-button" type="button" onClick={() => limitRateWSCalls(() => displayPropositions())} disabled={isRateLimitTimerActive}>
                      <PresentToAllIcon sx={{ fontSize: 24 }} />
                      Afficher aux joueurs
                    </button>
                  </div>
                </section>
              )}

              {/* DESSIN */}
              {question?.drawing?.active && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">dessins des joueurs :</span></p>
                  </div>

                  <div className="middle-content">
                    {(!playerDrawings || playerDrawings?.length <= 0) && (
                      <>
                        <p className="indication">Collectez les dessins des joueurs pour les voir apparaitre ici.</p>
                        <p className="indication">Attention : une fois collectées, les joueurs ne peuvent plus modifier leurs dessins !</p>
                      </>
                    )}

                    {playerDrawings && playerDrawings?.length > 0 && playerDrawings.map((playerDrawing) => (
                      <PlayerDrawing
                        key={playerDrawing.playerId}
                        username={playerDrawing.username}
                        path={playerDrawing.value}
                        backgroundUrl={question?.drawing?.url}
                        onGoodAnswer={() => playerGoodAnswer(playerDrawing.playerId, playerDrawing.username)}
                        displayDrawingToPlayers={() => displayDrawingToPlayers(playerDrawing.username, playerDrawing.value)}
                      />
                    ))}
                  </div>

                  <div className="right-content">
                    <button className="action-button collect-button" type="button" onClick={() => limitRateWSCalls(() => getDrawings())} disabled={isRateLimitTimerActive}>
                      <DownloadForOfflineIcon sx={{ fontSize: 24 }} />
                      Collecter les dessins
                    </button>
                  </div>
                </section>
              )}

              {/* REPONSES TEXTE */}
              {question.text && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">réponses des joueurs :</span></p>
                  </div>

                  <div className="middle-content">
                    {(!playerTextResponses || playerTextResponses.length <= 0) && (
                      <>
                        <p className="indication">Collectez les réponses des joueurs pour les voir apparaitre ici.</p>
                        <p className="indication">Attention : une fois collectées, les joueurs ne peuvent plus modifier leurs réponses !</p>
                      </>
                    )}

                    {playerTextResponses && playerTextResponses.length > 0 && playerTextResponses.map((playerTextResponse) => (
                      <PlayerTextResponse
                        key={playerTextResponse.playerId}
                        username={playerTextResponse.username}
                        value={playerTextResponse.value}
                        onGoodAnswer={() => playerGoodAnswer(playerTextResponse.playerId, playerTextResponse.username)}
                        displayToPlayers={() => displayTextToPlayers(playerTextResponse.username, playerTextResponse.value)}
                      />
                    ))}
                  </div>

                  <div className="right-content">                    
                    <button className="action-button collect-button" type="button" onClick={() => limitRateWSCalls(() => getTextResponses())} disabled={isRateLimitTimerActive}>
                      <DownloadForOfflineIcon sx={{ fontSize: 24 }} />
                      Collecter les réponses
                    </button>
                  </div>
                </section>
              )}
              
              {/* RESPONSE */}
              {question.response && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">réponse :</span></p>
                  </div>

                  <div className="middle-content">
                    <p>{question.propositions.find((prop) => prop.id === question.response)?.label || ''}</p>
                  </div>

                  <div className="right-content">
                    <button className="action-button" type="button" onClick={() => limitRateWSCalls(() => displayResponse())} disabled={isRateLimitTimerActive}>
                      <PresentToAllIcon sx={{ fontSize: 24 }} />
                      Afficher aux joueurs
                    </button>
                  </div>
                </section>
              )}

              {/* EXPLICIT RESPONSE */}
              {question.explicit_response && (
                <section className="container-element">
                  <div className="left-content">
                    <p><span className="element-label">Informations additionnelles :</span></p>
                  </div>

                  <div className="middle-content">
                    <p>{explicitResponse}</p>
                  </div>

                  <div className="right-content">
                    <button className="action-button" type="button" onClick={() => limitRateWSCalls(() => displayExplicitResponse())} disabled={isRateLimitTimerActive}>
                      <PresentToAllIcon sx={{ fontSize: 24 }} />
                      Afficher aux joueurs
                    </button>
                  </div>
                </section>
              )}
            </>
          )}

          <section id="container-spectator">
            <p>Fenêtre spectateur <span>(ce que les joueurs voient sur leurs écrans)</span></p>

            <button type="button" className="container-new-tab" onClick={openSpectatorInNewTab}>
              <p>Ouvrir dans un nouvel onglet</p>
              <OpenInNewIcon sx={{ fontSize: 18 }} />
            </button>

            {iframeSpectatorMode}
          </section>
        </div>
      </section>
    </div>
  );
}
