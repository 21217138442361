import * as React from 'react';
import './register.css';
import { toast } from 'react-toastify';

import * as apiUsers from '../../../../services/api/user';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';

export default function RegisterBackOffice() {
  const [email, setEmail] = React.useState('');
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const register = async (event) => {
    event.preventDefault();

    if (!email) {
      toast.error('Adresse email invalide');
      return;
    }

    setLoading(true);

    // Inscription du joueur
    try {
      await apiUsers.register({
        email,
      });
      
      toast.success('Email envoyé');

      setSent(true);
    } catch {
      toast.error('Échec de l\'inscription, veuillez réessayer plus tard.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-register">
      <h1>Inscription</h1>

      {!sent && (
        <>
          <form onSubmit={register}>
            <CustomLabel label="Adresse email" />
            <CustomInput value={email} onChange={setEmail} placeholder="ex : john.doe@email.com" autoFocus maxLength={128} />

            <CustomButton label="S'inscrire" loading={loading} type="submit" />
          </form>
        </>
      )}

      {sent && (
        <div className="container-confirmation-text">
          <p>Nous vous avons envoyé un email pour continuer votre inscription.</p>
          <p>Si vous n'avez pas reçu notre e-mail, veuillez vérifier votre dossier de courrier indésirable, il pourrait s'y trouver.</p>
          <p>Nous ne partageons pas vos données avec des tiers.</p>
        </div>
      )}
    </div>
  );
}
