import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './session.css';
import { toast } from 'react-toastify';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import apiQuizzes from '../../../services/api/quiz';

import * as socket from '../../../services/websocket';
import { ActionBack } from '../../../enums/action-back.enum';
import { ActionPlayer } from '../../../enums/action-player.enum';
import InGamePlayerCard from '../../../components/in-game-player-card';
import PropositionBloc from '../components/proposition-bloc';
import Buzzer from '../../../components/buzzer';
import NotStarted from './components/not-started';
import Instructions from './components/instructions';
import CustomInput from '../../../components/custom-input';
import PlayerTextResponse from '../../../components/player-text-reponse';
import PlayerDrawing from '../../../components/player-drawing';

import selfBuzz from '../../../assets/self-buzz.mp3';
import RouteNames from '../../../route-names';

let question;
let dispatchQuestion;

let contextToSet = null;

let hasBeenFirstQuestion;
let setHasBeenFirstQuestion;

let isBuzzVolumeActive;
let setIsBuzzVolumeActive;

let textResponse;
let setTextResponse;

let isTextResponseFocused;
let setIsTextResponseFocused;

export default function SessionGame() {
  const navigate = useNavigate();

  const initialQuestion = {
    title: '',
    theme: '',
    image: { credit: '', url: '' },
    audio: { credit: '', url: '' },
    propositions: null,
    response: '',
    explicitResponse: '',
    points: null,
    text: false,
    drawing: { active: false, credit: '', url: '' },
  };
  
  [hasBeenFirstQuestion, setHasBeenFirstQuestion] = React.useState(false);

  const [quizz, setQuizz] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [playerId, setPlayerId] = React.useState('');
  [question, dispatchQuestion] = React.useReducer(
    (state, action) => ({ ...state, ...action }),
    initialQuestion,
  );
  const [audioVolume, setAudioVolume] = React.useState(100);
  const audioRef = React.useRef(null);

  [isBuzzVolumeActive, setIsBuzzVolumeActive] = React.useState(true);
  const audioBuzzRef = React.useRef(null);

  [textResponse, setTextResponse] = React.useState('');
  const [isTextResponseDisabled, setIsTextResponseDisabled] = React.useState(false);
  const [playerTextResponse, setPlayerTextResponse] = React.useState('');
  [isTextResponseFocused, setIsTextResponseFocused] = React.useState(false);

  const drawingRef = React.useRef(null);
  const [isDrawingDisabled, setIsDrawingDisabled] = React.useState(false);
  const [playerDrawing, setPlayerDrawing] = React.useState('');

  const [playerBuzzed, setPlayerBuzzed] = React.useState('');

  const [triggerBuzzer, setTriggerBuzzer] = React.useState(false);

  const contextRef = React.useRef(null);

  const hasAnyMetadataQuestionElement = !!question.points
    || !!question.theme;
  const hasAnyMainQuestionElement = !!question?.title
    || !!question?.audio?.url
    || !!question?.image?.url
    || !!question?.propositions
    || !!question?.response;

  // Définir si le bouton buzzer est actif
  const isBuzzerDisabled =
    playerBuzzed ||
    !hasAnyMetadataQuestionElement ||
    question?.response ||
    question?.drawing?.active ||
    question?.text;

  React.useEffect(() => {
    // Récupérer les infos de la Game
    getQuizz();

    socket.setOnMessage(handleMesagesFromWsServer, refreshPlayerList);

    setPlayerId(localStorage.getItem('PLAYER_ID'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On met à jour la fonction de gestion des messages WS si la liste des joueurs est mise à jour
  // parce que la fonction "handleMesagesFromWsServer" est mise en cache par React avec la valeur
  // de "players" au moment où elle est créée, donc liste vide []
  React.useEffect(() => {
    socket.setOnMessage(handleMesagesFromWsServer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  const getQuizz = React.useCallback(async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByPlayerJwtToken();

      setQuizz(lightQuizz);
    } catch (err) {
      toast.error('Impossible de récupérer les infos du quiz');
    }
  }, []);

  // merci à : https://stackoverflow.com/a/2450976
  const shuffle = (array) => {
    let currentIndex = array.length;
    let randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const handleMesagesFromWsServer = (message) => {
    if (message?.action === ActionBack.BACK_PLAYER_LIST) {
      // Rafraichir liste des joueurs
      setPlayers(message.players);
    } else if (message?.action === ActionBack.BACK_Q_CONTEXT) {
      if (!hasBeenFirstQuestion) setHasBeenFirstQuestion(true);

      contextToSet = message;

      // Animation pour cacher le contexte actuel
      if (contextRef?.current) {
        contextRef.current.classList.remove('fade-in');
        contextRef.current.classList.add('fade-out');
      }

      // Reset la réponse texte quand on reçoit une nouvelle question
      setTextResponse('');

      // Reset la disponibilité de l'input texte réponse
      setIsTextResponseDisabled(false);

      // Reset la réponse texte d'un autre joueur
      setPlayerTextResponse('');

      // Reset la disponibilité du dessin
      setIsDrawingDisabled(false);

      // Reset le dessin d'un autre joueur
      setPlayerDrawing('');
    } else if (message?.action === ActionBack.BACK_Q_TITLE) {
      // Remplis le label de la question
      updateQuestion(message.element, { name: 'title' });
    } else if (message?.action === ActionBack.BACK_Q_IMAGE) {
      // Remplis l'image de la question
      updateQuestion(message.element, { name: 'image' });
    } else if (message?.action === ActionBack.BACK_Q_PROP) {
      // Si on a déjà des propositions, alors on les rafraichit pas
      // (on ne veut pas re-mélanger les propositions si on affiche la réponse après une validation de réponse)
      if (question.propositions) return;

      // Mélange des propositions
      const suffledPropositions = shuffle(message.element);

      // Remplis les propositions de la question
      updateQuestion(suffledPropositions, { name: 'propositions' });
    } else if (message?.action === ActionBack.BACK_Q_RES) {
      // Remplis la réponse de la question
      updateQuestion(message.element, { name: 'response' });
    } else if (message?.action === ActionBack.BACK_Q_EXP_RES) {
      // Remplis la réponse explicite de la question
      updateQuestion(message.element, { name: 'explicitResponse' });
    } else if (message?.action === ActionBack.BACK_Q_PLAY) {
      // Lance la lecture de l'audio
      if (audioRef && audioRef.current) audioRef.current.play();
    } else if (message?.action === ActionBack.BACK_Q_PAUSE) {
      // Met en pause la lecture de l'audio
      if (audioRef && audioRef.current) audioRef.current.pause();
    } else if (message?.action === ActionBack.BACK_P_BUZZ) {
      if (isBuzzVolumeActive && audioBuzzRef && audioBuzzRef.current) audioBuzzRef.current.play();

      // Remplis le joueur ayant buzzé
      setPlayerBuzzed(message?.playerId);

      const playerWhoBuzzed = players.find((p) => p.playerId === message?.playerId);

      // Si on trouve bien le joueur qui a buzzé dans la liste des joueurs
      // et que le joueur qui buzz n'est pas l'utilisateur courant
      // alors on affiche une notification
      if (playerWhoBuzzed && playerWhoBuzzed.playerId !== playerId) {
        // Notification qu'un joueur a buzzé
        toast.success(
          `${playerWhoBuzzed.username} a buzzé !`,
          {
            autoClose: 2000,
          },
        );
      } else if (playerWhoBuzzed && playerWhoBuzzed.playerId === playerId) {
        // Notification qu'un joueur a buzzé
        toast.success(
          `A vous de répondre !`,
          {
            autoClose: 3000,
            theme: 'colored',
          },
        );
      }
    } else if (message?.action === ActionBack.BACK_P_UNBUZZ) {
      // Libère le buzz
      setPlayerBuzzed('');
    } else if (message?.action === ActionBack.BACK_END_QUIZZ) {
      // Naviguer vers la page leaderboard
      navigate(RouteNames.GAME_LEADERBOARD);
    } else if (message?.action === ActionBack.BACK_P_BEEN_EXCLUDED) {
      // Process d'exclusion
      socket.cleanUpExcluded();
    } else if (message?.action === ActionBack.BACK_COLLECT_TEXT_RESPONSE) {
      // Envoyer la réponse texte au serveur
      socket.sendMessage({
        action: ActionPlayer.SEND_TEXT_RESPONSE,
        value: textResponse,
      });

      // Disable l'input de la réponse texte
      setIsTextResponseDisabled(true);
    } else if (message?.action === ActionBack.BACK_DISPLAY_TEXT_RESPONSE) {
      // Afficher la réponse texte d'un joueur
      setPlayerTextResponse({
        username: message?.username,
        value: message?.value,
      });
    } else if (message?.action === ActionBack.BACK_COLLECT_DRAWING) {
      sendDrawingToServer();
    } else if (message?.action === ActionBack.BACK_DISPLAY_DRAWING) {
      // Afficher le dessin d'un joueur
      setPlayerDrawing({
        username: message?.username,
        value: message?.value,
      });
    }
  };

  const sendDrawingToServer = async () => {
    const path = await drawingRef?.current?.exportPaths();

    // Envoyer le dessin au serveur
    socket.sendMessage({
      action: ActionPlayer.SEND_DRAWING,
      value: JSON.stringify(path),
    });

    // Disable le dessin
    setIsDrawingDisabled(true);
  };

  const contextAnimationEnd = (data) => {
    if (data.animationName === 'fadeOut') {
      dispatchQuestion({
        ...initialQuestion,
        points: contextToSet.points,
        theme: contextToSet.theme,
        text: contextToSet.text,
        drawing: contextToSet.drawing,
      });

      if (contextToSet.audio) updateQuestion(contextToSet.audio, { name: 'audio' });

      // Animation pour afficher le nouveau contexte
      if (contextRef?.current) {
        contextRef.current.classList.remove('fade-out');
        contextRef.current.classList.add('fade-in');
      }
    }


    if (data.animationName === 'fadeIn') {
      contextToSet = null;
    }
  };

  // Fonction de mise à jour de l'objet reducer global qui fonctionne pour tous les champs
  const updateQuestion = (value, { name }) => {
    dispatchQuestion({ [name]: value });
  };

  const refreshPlayerList = React.useCallback(() => {
    socket.sendMessage({
      action: ActionPlayer.PLAYER_LIST,
    });
  }, []);

  const buzz = React.useCallback(() => {
    // On ne peut pas buzzer si un joueur a déjà buzzé
    // OU si aucune info metadata de la question n'est affichée
    // OU si on a reçu la réponse à la question
    if (isBuzzerDisabled) return;

    socket.sendMessage({
      action: ActionPlayer.PLAYER_BUZZ,
    });
  }, [playerBuzzed, question.points, question.theme, question.response, question.drawing?.active, question.text]);

  const shortcutBuzzDown = (event) => {
    if (!isTextResponseFocused && !triggerBuzzer && event.key === 'b') {
      setTriggerBuzzer(true);
    }
  };

  const shortcutBuzzUp = () => {
    setTriggerBuzzer(false);
  };

  // Effect pour que la touche 'b' permette de buzzer
  React.useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', shortcutBuzzDown);
    document.addEventListener('keyup', shortcutBuzzUp);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', shortcutBuzzDown);
      document.removeEventListener('keyup', shortcutBuzzUp);
    };
  }, [shortcutBuzzDown, shortcutBuzzUp]);

  const changeVolume = (event) => {
    if (!audioRef || !audioRef.current || !audioRef.current) return;

    const newVolumeValue = event?.target?.value || 50;

    // Modifie le slider de volume
    setAudioVolume(newVolumeValue);

    // Modifie le volume du player audio
    audioRef.current.volume = newVolumeValue / 100;
  };

  const onChangeBuzzVolumeActive = (event) => {
    setIsBuzzVolumeActive(event?.target?.checked);
  };

  const focusTextInput = () => {
    setIsTextResponseFocused(true);
  };

  const blurTextInput = () => {
    setIsTextResponseFocused(false);
  };
  
  const urlify = (text) => {
    if (!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split(urlRegex)
      .map(part => {
        if (part.match(urlRegex)) {
          return <a href={part} target="_blank" key={part}> {part} </a>;
        }
        return part;
      });
  };

  const creditImage = React.useMemo(() => urlify(question?.image?.credit), [question?.image?.credit]);
  const creditAudio = React.useMemo(() => urlify(question?.audio?.credit), [question?.audio?.credit]);
  const explicitResponse = React.useMemo(() => urlify(question?.explicitResponse), [question?.explicitResponse]);

  // Tri DESC des joueurs par score
  const sortedPlayers = React.useMemo(() => players.sort((a, b) => b.score - a.score), [players]);

  return (
    <div className="container-player-session">
      <section id="container-left-content">
        <div>
          <h1>{quizz?.label}</h1>

          <div id="player-list">
            {sortedPlayers.map((player) => (
              <InGamePlayerCard
                key={player.playerId}
                content={player.username}
                score={player.score}
                isMe={player.playerId === playerId}
                isBuzzed={player.playerId === playerBuzzed}
              />
            ))}
          </div>
        </div>

        <audio ref={audioBuzzRef}>
          <source src={selfBuzz} />
        </audio>

        <div className="container-buzzer">
          <Buzzer onClick={buzz} trigger={triggerBuzzer} disabled={isBuzzerDisabled} />

          <div className="container-toggle">
            <p>Son buzzer</p>
            <input type="checkbox" checked={isBuzzVolumeActive} onChange={onChangeBuzzVolumeActive} />
          </div>
        </div>
      </section>

      <section id="container-right-content">
        <div ref={contextRef} id="container-context" style={{ padding: !(question?.points || question?.theme) ? '0px' : '8px 24px' }} onAnimationEnd={contextAnimationEnd}>
          {question?.points && (<p id="points">{question.points + ' pts'}</p>)}
          
          {question?.theme && (<p id="theme">{question.theme}</p>)}
        </div>

        {question?.title && (<h1>{question?.title}</h1>)}

        <div id="container-elements">
          {!hasBeenFirstQuestion && (
            <NotStarted />
          )}

          {hasBeenFirstQuestion && !hasAnyMainQuestionElement && (
            <Instructions />
          )}

          {question?.audio?.credit && question?.response && (
            <p id="credit">Source : {creditAudio}</p>
          )}

          {question?.audio?.url && (
            <div id="container-audio">
              <audio ref={audioRef}>
                <source src={question.audio.url} />
              </audio>

              <p className="indication">De l'audio sera joué pour cette question, vous pouvez régler le volume ici</p>

              <div id="audio-controls">
                <VolumeDownIcon sx={{ fontSize: 20 }} />

                <input type="range" min="0" max="100" value={audioVolume} onChange={changeVolume} />

                <VolumeUpIcon sx={{ fontSize: 20 }} />
              </div>
            </div>
          )}

          {question?.image?.credit && question?.response && (
            <p id="credit">Source : {creditImage}</p>
          )}

          {question?.image?.url && (
            <img src={question.image.url} alt="illustration de la question" />
          )}

          {/* On affiche le text input pour envoyer la réponse quand le titre de la question est affiché */}
          {question?.text && question?.title && (
            <>
              <CustomInput
                value={textResponse}
                onChange={setTextResponse}
                placeholder="ex : Vincent Van Gogh"
                maxLength={64}
                autoFocus
                disabled={isTextResponseDisabled}
                onBlur={blurTextInput}
                onFocus={focusTextInput}
              />

              <p className="indication">
                {isTextResponseDisabled ? 
                  `L'animateur a récupéré votre réponse, vous ne pouvez plus la modifier`
                  :
                  `Tapez votre réponse et attendez que l'animateur collecte les réponses. Attention : vous ne pourrez plus modifier votre réponse une fois qu'elle sera collectée !`
                }
              </p>

              {playerTextResponse && (
                <PlayerTextResponse
                  username={playerTextResponse.username}
                  value={playerTextResponse.value}
                />
              )}
            </>
          )}

          {/* On affiche la zone de dessin pour envoyer le dessin quand le titre de la question est affiché */}
          {question?.drawing?.active && question?.title && (
            <>
              {!isDrawingDisabled && (
                <ReactSketchCanvas
                  ref={drawingRef}
                  className={question?.drawing?.url && 'with-background'}
                  style={{
                    width: '500px',
                    height: '500px',
                    border: "0.0625rem solid #9c9c9c",
                    ...(question?.drawing?.url && {
                      backgroundImage: `url(${question.drawing.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }),
                  }}
                  strokeWidth={4}
                  strokeColor="black"
                />
              )}

              <p className="indication">
                {isDrawingDisabled ? 
                  `L'animateur a récupéré votre dessin`
                  :
                  `Dessinez votre réponse et attendez que l'animateur collecte les dessins. Attention : vous ne pourrez plus modifier votre dessin une fois qu'il aura été collecté !`
                }
              </p>

              {playerDrawing && (
                <PlayerDrawing
                  username={playerDrawing.username}
                  path={playerDrawing.value}
                  backgroundUrl={question?.drawing?.url}
                />
              )}
            </>
          )}

          {question?.propositions && (
            <div id="container-propositions">
              {question.propositions.map((prop) => (
                <PropositionBloc
                  key={prop.id}
                  label={prop.label}
                  isResponse={question?.response && question.response === prop.id}
                />
              ))}
            </div>
          )}

          {question?.explicitResponse && (
            <p id="credit">Informations additionnelles : {explicitResponse}</p>
          )}
        </div>
      </section>
    </div>
  );
}
