import * as React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactSketchCanvas } from "react-sketch-canvas";
import ShortUniqueId from 'short-unique-id';
import AddIcon from '@mui/icons-material/Add';

import './form-question.css';

import apiQuestion from '../../../../services/api/question';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';
import PropositionBloc from './components/proposition-bloc';

export default function FormQuestionBackOffice() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [isCreating, setIsCreating] = React.useState(true);
  const audioRef = React.useRef(null);

  const [question, dispatchQuestion] = React.useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      title: '',
      theme: '',
      image: null,
      audio: null,
      propositions: [],
      response: '',
      explicitResponse: '',
      points: 0,
      text: false,
      drawing: null,
    },
  );

  const [isShownImageForm, setIsShownImageForm] = React.useState(false);
  const [isShownAudioForm, setIsShownAudioForm] = React.useState(false);
  const [isShownDrawingForm, setIsShownDrawingForm] = React.useState(false);

  React.useEffect(() => {
    // On définit si on est sur le formulaire pour une création de question ou non
    setIsCreating(/creer-question/.test(location.pathname));
  }, [location]);

  React.useEffect(() => {
    if (!isCreating) {
      getQuestion();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreating]);

  const getQuestion = async () => {
    try {
      const fetchedQuestion = await apiQuestion.getQuestion(params.quizzId, params.questionId);

      // Affichage du formulaire image si il y en a déjà une sur la question
      if (fetchedQuestion?.image?.url) setIsShownImageForm(true);

      // Affichage du formulaire audio si il y en a déjà un sur la question
      if (fetchedQuestion?.audio?.url) setIsShownAudioForm(true);

      dispatchQuestion(fetchedQuestion);
    } catch {
      toast.error('La question n\'a pas pu être récupérée');
    }
  };

  // Fonction de mise à jour de l'objet reducer global qui fonctionne pour tous les champs
  const updateQuestion = (value, { name }) => {
    dispatchQuestion({ [name]: value });
  };

  // Fonction spéciale pour l'image vu que c'est un objet
  const updateImage = (value, { name }) => {
    dispatchQuestion({ image: { ...question.image, [name]: value } });
  };

  // Fonction spéciale pour l'audio pour recharger le player à chaque modif
  const updateAudio = (value, { name }) => {
    audioRef?.current?.load();

    dispatchQuestion({ audio: { ...question.audio, [name]: value } });
  };

  // Fonction spéciale pour l'image du dessin vu que c'est un objet
  const updateDrawing = (value, { name }) => {
    dispatchQuestion({ drawing: { ...question.drawing, [name]: value } });
  };

  const addProposition = () => {
    let newPropositions = null;
    const propositionId = new ShortUniqueId({ length: 16 });

    if (!question.propositions || question.propositions?.length <= 0) {
      newPropositions = [{ label: '', id: propositionId.rnd() }];
    } else {
      newPropositions = [...question.propositions, { label: '', id: propositionId.rnd() }];
    }

    updateQuestion(newPropositions, { name: 'propositions' });
  };

  const delProposition = (index) => {
    const newPropositions = question.propositions.slice();

    newPropositions.splice(index, 1);

    updateQuestion(newPropositions, { name: 'propositions' });
  };

  const changeProposition = (index, event) => {
    const newPropositions = question.propositions.slice();
    newPropositions[index].label = event.target.value;

    updateQuestion(newPropositions, { name: 'propositions' });
  };

  const chooseResponse = (index) => {
    updateQuestion(question.propositions[index].id, { name: 'response' });
  };

  const confirm = async (event) => {
    event.preventDefault();

    // On nettoie les propositions qui n'ont pas de label
    let cleanPropositions = [];
    if (question.propositions && question.propositions.length > 0) {
      cleanPropositions = question.propositions.filter((prop) => prop.label);
    }

    if (isCreating) {
      try {
        await apiQuestion.createQuestion(
          params.quizzId,
          {
            ...question,
            propositions: cleanPropositions,
            points: +question.points,
          },
        );
  
        toast.success('La question a été créée');
  
        // redirection vers le détail du quiz
        navigate('..', { relative: 'path' });
      } catch {
        toast.error('La question n\'a pas pu être créée');
      }
    } else {
      try {
        await apiQuestion.updateQuestion(
          params.quizzId,
          params.questionId,
          {
            title: question.title,
            theme: question.theme,
            image: question.image,
            audio: question.audio,
            propositions: cleanPropositions,
            response: question.response,
            points: +question.points,
            text: question.text,
            drawing: question.drawing,
            explicitResponse: question.explicitResponse,
          },
        );
  
        toast.success('La question a été mise à jour');
  
        // redirection vers le détail du quiz
        navigate('../..', { relative: 'path' });
      } catch {
        toast.error('La question n\'a pas pu être mise à jour');
      }
    }
  };

  const addImage = () => {
    setIsShownImageForm(true);
  };

  const removeImage = () => {
    dispatchQuestion({ image: null });
    setIsShownImageForm(false);
  };

  const addAudio = () => {
    setIsShownAudioForm(true);
  };

  const removeAudio = () => {
    dispatchQuestion({ audio: null });
    setIsShownAudioForm(false);
  };

  const addDrawing = () => {
    dispatchQuestion({ drawing: { active: true, url: '', credit: '' } });
    setIsShownDrawingForm(true);
  };

  const removeDrawing = () => {
    dispatchQuestion({ drawing: null });
    setIsShownDrawingForm(false);
  };

  const decrementPoints = () => {
    if (question.points <= 0) return;

    dispatchQuestion({ points: --question.points });
  };

  const incrementPoints = () => {
    dispatchQuestion({ points: ++question.points });
  };

  const triggerTextFormat = (event) => {
    if (typeof event?.target?.checked !== 'undefined') dispatchQuestion({ text: event.target.checked });
  };

  return (
    <div className="container-form-question">
      <section className="container-title">
        <h1>{isCreating ? 'Créer une question' : 'Editer la question'}</h1>

        <p>Tous les champs sont facultatifs, construisez la question comme vous souhaitez</p>
      </section>

      <form onSubmit={confirm}>
        <div className="container-input">
          <CustomLabel label="Label" />
          <CustomInput
            value={question.title || ''}
            onChange={updateQuestion}
            placeholder={`ex : Qui a peint le tableau "La Joconde" ?`}
            customAttributes={{ 'data-name': 'title' }}
            maxLength={256}
            autoFocus
          />
        </div>

        <div className="container-input">
          <CustomLabel label="Thème" />
          <CustomInput
            value={question.theme || ''}
            onChange={updateQuestion}
            placeholder="ex : art"
            customAttributes={{ 'data-name': 'theme' }}
            maxLength={128}
          />
        </div>

        <div className="container-input">
          {isShownImageForm || question?.image?.url ? (
            <>
              <div className="container-label">
                <CustomLabel label="Image" />

                <button className="action-button" type="button" onClick={removeImage}>retirer</button>
              </div>

              <p className="label-second">URL de l'image</p>
              <CustomInput
                value={question?.image?.url || ''}
                onChange={updateImage}
                placeholder="ex : https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_natural_color.jpg/514px-Mona_Lisa%2C_by_Leonardo_da_Vinci%2C_from_C2RMF_natural_color.jpg?uselang=fr"
                customAttributes={{ 'data-name': 'url' }}
              />

              <p className="label-second">Crédit pour l'utilisation de l'image</p>
              <CustomInput
                value={question?.image?.credit || ''}
                onChange={updateImage}
                placeholder="ex : Domaine public"
                customAttributes={{ 'data-name': 'credit' }}
              />

              <div className="container-image">
                {question?.image?.url
                  ? (
                    <img
                      src={question.image.url}
                      alt="prévisualisation"
                    />
                  )
                  : (
                    <p>Ajoutez l'URL d'une image valide pour avoir la prévisualisation</p>
                  )}
              </div>
            </>
          ) : (
            <>
              <div className="container-label">
                <CustomLabel label="Image" />
              </div>

              <button className="action-button" type="button" onClick={addImage}>
                <AddIcon sx={{ fontSize: 20 }} />
                Ajouter une image
              </button>
            </>
          )}
        </div>

        <div className="container-input">
          {isShownAudioForm || question?.audio?.url ? (
            <>
              <div className="container-label">
                <CustomLabel label="Audio" />

                <button className="action-button" type="button" onClick={removeAudio}>retirer</button>
              </div>

              <p className="label-second">URL de l'audio</p>
              <CustomInput
                value={question?.audio?.url || ''}
                onChange={updateAudio}
                placeholder="ex : https://samplelib.com/lib/preview/mp3/sample-6s.mp3"
                customAttributes={{ 'data-name': 'url' }}
              />
              
              <p className="label-second">Crédit pour l'utilisation de l'audio</p>
              <CustomInput
                value={question?.audio?.credit || ''}
                onChange={updateAudio}
                placeholder="ex : Domaine public"
                customAttributes={{ 'data-name': 'credit' }}
              />

              <div className="container-audio">
                {question?.audio?.url
                  ? (
                    <audio controls ref={audioRef}>
                      <source src={question.audio.url} />
                    </audio>
                  )
                  : (
                    <p>Ajoutez l'URL d'une piste audio valide pour avoir la prévisualisation</p>
                  )}
              </div>
            </>
          ) : (
            <>
              <div className="container-label">
                <CustomLabel label="Audio" />
              </div>

              <button className="action-button" type="button" onClick={addAudio}>
                <AddIcon sx={{ fontSize: 20 }} />
                Ajouter un audio
              </button>
            </>
          )}
        </div>

        <div className="container-input">
          <>
            <div className="container-label">
              <CustomLabel label="Texte" />
            </div>

            <div className="container-checkbox">
              <input type="checkbox" name="text-format" checked={question.text} onChange={triggerTextFormat} />
              <label htmlFor="text-format" >Les joueurs répondent sous forme de texte. Chaque joueur enverra une réponse.</label>
            </div>
          </>
        </div>

        <div className="container-input">
          {(isShownDrawingForm || question?.drawing?.active) ? (
            <>
              <div className="container-label">
                <CustomLabel label="Dessin" />

                <button className="action-button" type="button" onClick={removeDrawing}>retirer</button>
              </div>

              <p className="label-second">URL d'une image à mettre en fond du dessin</p>
              <CustomInput
                value={question?.drawing?.url || ''}
                onChange={updateDrawing}
                placeholder="ex : https://d-maps.com/m/oceania/australie/australie02.gif"
                customAttributes={{ 'data-name': 'url' }}
              />

              <p className="label-second">Crédit pour l'utilisation de l'image de fond</p>
              <CustomInput
                value={question?.drawing?.credit || ''}
                onChange={updateDrawing}
                placeholder="ex : https://d-maps.com"
                customAttributes={{ 'data-name': 'credit' }}
              />

              <div className="container-drawing">
                <ReactSketchCanvas
                  style={{
                    width: '500px',
                    height: '500px',
                    border: "0.0625rem solid #9c9c9c",
                    ...(question?.drawing?.url && {
                      backgroundImage: `url(${question.drawing.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }),
                  }}
                  strokeWidth={4}
                  strokeColor="black"
                  readOnly
                />
              </div>
            </>
          ) : (
            <>
              <div className="container-label">
                <CustomLabel label="Dessin" />
              </div>

              <button className="action-button" type="button" onClick={addDrawing}>
                <AddIcon sx={{ fontSize: 20 }} />
                Ajouter un dessin
              </button>
            </>
          )}
        </div>

        <div className="container-input">
          <CustomLabel label="Propositions" />

          <p className="note">Les propositions seront mélangées à l'affichage</p>

          {question?.propositions?.length < 4 && (
            <button className="action-button" type="button" onClick={addProposition} disabled={question?.propositions?.length >= 4}>
              <AddIcon sx={{ fontSize: 20 }} />
              ajouter une proposition
            </button>
          )}

          <div className="container-propositions">
            <PropositionBloc
              isDefined={question?.propositions?.length > 0}
              label={question?.propositions?.length > 0 && question?.propositions[0]?.label}
              index={0}
              delProp={delProposition}
              onChange={changeProposition}
              chooseResponse={chooseResponse}
              isResponse={
                question?.response
                && question?.propositions?.length > 0
                && question?.response === question?.propositions[0]?.id
              }
            />

            <PropositionBloc
              isDefined={question?.propositions?.length > 1}
              label={question?.propositions?.length > 1 && question?.propositions[1]?.label}
              index={1}
              delProp={delProposition}
              onChange={changeProposition}
              chooseResponse={chooseResponse}
              isResponse={
                question?.response
                && question?.propositions?.length > 0
                && question?.response === question?.propositions[1]?.id
              }
            />

            <PropositionBloc
              isDefined={question?.propositions?.length > 2}
              label={question?.propositions?.length > 2 && question?.propositions[2]?.label}
              index={2}
              delProp={delProposition}
              onChange={changeProposition}
              chooseResponse={chooseResponse}
              isResponse={
                question?.response
                && question?.propositions?.length > 0
                && question?.response === question?.propositions[2]?.id
              }
            />

            <PropositionBloc
              isDefined={question?.propositions?.length > 3}
              label={question?.propositions?.length > 3 && question?.propositions[3]?.label}
              index={3}
              delProp={delProposition}
              onChange={changeProposition}
              chooseResponse={chooseResponse}
              isResponse={
                question?.response
                && question?.propositions?.length > 0
                && question?.response === question?.propositions[3]?.id
              }
            />
          </div>
        </div>

        <div className="container-input">
          <CustomLabel label="Informations additionnelles" />
          <p className="note">Des informations additionnelles à afficher aux joueurs</p>

          <CustomInput
            value={question.explicitResponse || ''}
            onChange={updateQuestion}
            placeholder={`ex : Léonard de Vinci`}
            customAttributes={{ 'data-name': 'explicitResponse' }}
            maxLength={256}
          />
        </div>

        <div className="container-input">
          <CustomLabel label="Points" />

          <div className="container-points-input">
            <button type="button" onClick={decrementPoints} disabled={question.points <= 0}>-</button>
            <p>{question.points}</p>
            <button type="button" onClick={incrementPoints}>+</button>
          </div>
        </div>

        <div className="container-confirm-button">
          <CustomButton label="Confirmer" type="submit" />
        </div>
      </form>
    </div>
  );
}
