import * as React from 'react';
import './audio-player.css';

import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export default function AudioPlayer(props) {
  const audioRef = React.useRef(null);
  const [audioVolume, setAudioVolume] = React.useState(100);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const changeVolume = (event) => {
    if (!audioRef || !audioRef.current || !audioRef.current) return;

    const newVolumeValue = event?.target?.value || 50;

    // Modifie le slider de volume
    setAudioVolume(newVolumeValue);

    // Modifie le volume du player audio
    audioRef.current.volume = newVolumeValue / 100;
  };

  const play = () => {
    props.onPlay();

    audioRef.current.play();

    setIsPlaying(true);
  };

  const pause = () => {
    props.onPause();

    audioRef.current.pause();

    setIsPlaying(false);
  };

  const audioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div id="audio-player">
      <audio ref={audioRef} onEnded={audioEnded}>
        <source src={props.src} />
      </audio>

      {isPlaying ? (
        <button onClick={pause}>
          <PauseIcon sx={{ fontSize: 28 }} />
        </button>
      ) : (
        <button onClick={play}>
          <PlayArrowIcon sx={{ fontSize: 28 }} />
        </button>
      )}

      <div id="separator" />

      <div id="volume-control">
        <VolumeDownIcon sx={{ fontSize: 20 }} />

        <input type="range" min="0" max="100" value={audioVolume} onChange={changeVolume} />

        <VolumeUpIcon sx={{ fontSize: 20 }} />
      </div>
    </div>
  );
}
