export const ActionBack = {
  BACK_REGISTER: 'BACK_REGISTER',
  BACK_PLAYER_LIST: 'BACK_PLAYER_LIST',
  BACK_REGISTER_ADMIN: 'BACK_REGISTER_ADMIN',
  BACK_START_QUIZZ: 'BACK_START_QUIZZ',
  BACK_END_QUIZZ: 'BACK_END_QUIZZ',

  BACK_Q_CONTEXT: 'BACK_Q_CONTEXT',
  BACK_Q_COMPLETE: 'BACK_Q_COMPLETE',
  BACK_Q_TITLE: 'BACK_Q_TITLE',
  BACK_Q_IMAGE: 'BACK_Q_IMAGE',
  BACK_Q_PROP: 'BACK_Q_PROP',
  BACK_Q_RES: 'BACK_Q_RES',
  BACK_Q_EXP_RES: 'BACK_Q_EXP_RES',
  BACK_Q_PLAY: 'BACK_Q_PLAY',
  BACK_Q_PAUSE: 'BACK_Q_PAUSE',
  BACK_P_GOOD_ANSWER: 'BACK_P_GOOD_ANSWER',
  BACK_P_BAD_ANSWER: 'BACK_P_BAD_ANSWER',
  BACK_P_EXCLUDE: 'BACK_P_EXCLUDE',
  BACK_P_BEEN_EXCLUDED: 'BACK_P_BEEN_EXCLUDED',
  BACK_P_BUZZ: 'BACK_P_BUZZ',
  BACK_P_UNBUZZ: 'BACK_P_UNBUZZ',

  BACK_COLLECT_TEXT_RESPONSE: 'BACK_COLLECT_TEXT_RESPONSE',
  BACK_SEND_TEXT_RESPONSE: 'BACK_SEND_TEXT_RESPONSE',
  BACK_DISPLAY_TEXT_RESPONSE: 'BACK_DISPLAY_TEXT_RESPONSE',

  BACK_COLLECT_DRAWING: 'BACK_COLLECT_DRAWING',
  BACK_SEND_DRAWING: 'BACK_SEND_DRAWING',
  BACK_DISPLAY_DRAWING: 'BACK_DISPLAY_DRAWING',

  BACK_REGISTER_FAILED: 'BACK_REGISTER_FAILED',
};
