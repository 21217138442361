import * as React from 'react';
import './loader.css';

export default function Loader(props) {
  return (
    <span
      className="loader"
      style={{
        display: props.loading ? 'inline-block' : 'none',
      }}
    ></span>
  );
}
