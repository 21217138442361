import { useNavigate } from 'react-router-dom';
import './not-found.css';

import CustomTextButton from '../../components/custom-text-button';

export default function NotFound() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('..');
  };

  return (
    <div className='container-not-found'>
      <h1>Oops!</h1>

      <p>La page n'existe pas !</p>

      <CustomTextButton label="Revenir à l'accueil" onClick={goToHome} />
    </div>
  );
}
