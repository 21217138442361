import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getGame = async () => {
  try {
    const gameToken = localStorage.getItem('GAME_TOKEN');

    if (!gameToken) throw new Error();

    const response = await axios.get(
      `${API_URL}/games/current`,
      {
        headers: {
          Authorization: `Bearer ${gameToken}`,
        }
      });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getGame = getGame;

export default api;
