import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './form-random-question.css';

import * as apiUser from '../../../../services/api/user';
import apiQuestion from '../../../../services/api/question';

import CustomLabel from '../../../../components/custom-label';
import CustomCheckboxCard from '../../../../components/custom-checkbox-card';
import CustomButton from '../../../../components/custom-button';

export default function FormRandomQuestionBackOffice() {
  const navigate = useNavigate();
  const params = useParams();

  const [subscription, setSubscription] = React.useState(null);
  const [themes, setThemes] = React.useState([]);
  const [checkedThemes, setCheckedThemes] = React.useState([]);
  const [nbQuestions, setNbQuestions] = React.useState(1);

  React.useEffect(() => {
    // Récupération de l'abonnement de l'utilisateur
    getCurrentSubscription();

    // Récupération des thèmes
    getQuestionsThemes();
  }, []);

  const getCurrentSubscription = async () => {
    try {
      const sub = await apiUser.getCurrentSubscription();

      setSubscription(sub);
    } catch {
      //
    }
  };

  const getQuestionsThemes = async () => {
    try {
      const tmpThemes = await apiQuestion.getQuestionsThemes();

      setThemes(tmpThemes);
    } catch {
      //
    }
  };

  const changeNbQuestions = (event) => {
    const newNbQuestions = event?.target?.value || 0;

    setNbQuestions(newNbQuestions);
  };

  const checkTheme = (theme) => {
    const isThemeAlreadyChecked = checkedThemes.includes(theme.theme);

    let newCheckedThemes = checkedThemes;

    if (!isThemeAlreadyChecked) {
      newCheckedThemes = [...checkedThemes, theme.theme];
    } else {
      newCheckedThemes = checkedThemes.filter((t) => t !== theme.theme);
    }

    setCheckedThemes(newCheckedThemes);

    return;
  };

  const checkAllThemes = () => {
    if (checkedThemes.length === themes.length) {
      setCheckedThemes([]);
    } else {
      setCheckedThemes(themes.map((t) => t.theme));
    }
  };

  const confirm = async (event) => {
    event.preventDefault();

    try {
      await apiQuestion.createRandomQuestions(
        params.quizzId,
        {
          themes: checkedThemes,
          quantity: Number(nbQuestions),
        },
      );

      toast.success('Questions ajoutées !');

      // redirection vers le détail du quiz
      navigate('..', { relative: 'path' });
    } catch {
      toast.error('Les questions n\'ont pas pu être ajoutées. Veuillez réessayer plus tard.');
    }
  };

  return (
    <div className="container-form-random-question">
      <section className="container-title">
        <h1>Ajouter des questions aléatoires</h1>
      </section>

      <form onSubmit={confirm}>
        <div className="container-input">
          <CustomLabel label="Nombre de questions à ajouter" />
          <div className="container-slider">
            <p>{nbQuestions}</p>
            <input type="range" min="1" max={subscription?.maxNbQuestions || '20'} value={nbQuestions} onChange={changeNbQuestions} />
          </div>
        </div>

        <div className="container-input">
          <CustomLabel label="Thèmes" />

          <button type="button" onClick={checkAllThemes}>
            {checkedThemes.length === themes.length ? 'Déselectionner' : 'Sélectionner'} tous les thèmes
          </button>

          <div className="container-checkboxes">
            {themes.map((theme, i) => (
              <CustomCheckboxCard
                key={i}
                label={`${theme.theme} (${theme.count})`}
                onClick={() => checkTheme(theme)}
                isChecked={checkedThemes.includes(theme.theme)}
              />
            ))}
          </div>
        </div>

        <div className="container-confirm-button">
          <CustomButton label="Confirmer" type="submit" />
        </div>
      </form>
    </div>
  );
}
