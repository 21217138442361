import * as React from 'react';
import './forgot-password.css';
import { toast } from 'react-toastify';

import * as apiUsers from '../../../../services/api/user';

import CustomLabel from '../../../../components/custom-label';
import CustomInput from '../../../../components/custom-input';
import CustomButton from '../../../../components/custom-button';

export default function ForgotPasswordBackOffice() {
  const [email, setEmail] = React.useState('');
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const forgotPassword = async (event) => {
    event.preventDefault();

    if (!email) {
      toast.error('Adresse email invalide');
      return;
    }

    setLoading(true);

    // Initiation processus de mot de passe oublié
    try {
      await apiUsers.forgotPassword({
        email,
      });

      toast.success('Email envoyé');

      setSent(true);
    } catch (err) {
      toast.error('Mail ou mot de passe incorrect');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-forgot-password">
      <h1>Mot de passe oublié</h1>

      {!sent && (
        <>
          <form onSubmit={forgotPassword}>
            <CustomLabel label="Adresse email" />
            <CustomInput value={email} onChange={setEmail} placeholder="ex : john.doe@email.com" maxLength={128} autoFocus />

            <CustomButton label="Envoyer" loading={loading} type="submit" />
          </form>
        </>
      )}

      {sent && (
        <div className="container-confirmation-text">
          <p>Nous vous avons envoyé un email pour continuer la procédure d'oubli de mot de passe.</p>
          <p>Cliquez sur le lien dans le mail pour définir un nouveau mot de passe.</p>
          <p>Si vous n'avez pas reçu notre e-mail, veuillez vérifier votre dossier de courrier indésirable, il pourrait s'y trouver.</p>
        </div>
      )}
    </div>
  );
}
