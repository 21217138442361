import { useNavigate, useMatches, useLocation } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import './breadcrumbs.css';

export default function Breadcrumbs() {
  const navigate = useNavigate();
  const matches = useMatches();
  const location = useLocation();

  const currentMatch = matches.find((m) => m.pathname === location.pathname);

  const goBack = () => {
    let destination = currentMatch.handle.goBack;

    if (!destination) {
      navigate(-1);
      return;
    }

    // On remplace les params de l'URL
    Object.keys(currentMatch.params).forEach((key) => {
      destination = destination.replaceAll(`{{${key}}}`, currentMatch.params[key]);
    });

    navigate(destination);
  };

  if (!currentMatch.handle?.goBack) return null;

  return (
    <div className="breadcrumbs">
      <button onClick={goBack}>
        <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
        retour
      </button>
    </div>
  );
}
