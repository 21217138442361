import './custom-button.css';
import Loader from '../loader';

export default function CustomButton(props) {
  return (
    <button
      className="custom-button"
      onClick={props.onClick}
      style={{
        color: props.loading ? 'transparent' : 'var(--input-background)',
        cursor: (props.disabled || props.loading) ? 'default' : 'pointer',
        backgroundColor: props.secondary ? 'var(--error)' : 'var(--primary)',
      }}
      disabled={props.disabled || props.loading}
      type={props.type || "button"}
    >
      {props.leftIcon && (
        <span id="left-icon">
          {props.leftIcon}
        </span>
      )}

      {props.label}

      {props.rightIcon && (
        <span id="right-icon">
          {props.rightIcon}
        </span>
      )}

      <Loader loading={props.loading} />
    </button>
  );
}
