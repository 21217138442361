export let socket = null;
let timeoutConnection = null;

let gameToken = null;

export const init = (cb, token = null) => {
  // Construction de l'URL de connexion au serveur ws
  let wsUrl = process.env.REACT_APP_WS_URL;
  if (token) wsUrl += `?token=${token}`;

  socket = new WebSocket(wsUrl);

  socket.onopen = function() {
    heartbeat();

    // Appel callback
    if (!!cb) cb();
  };

  socket.onclose = function () {
    clearTimeout(this.pingTimeout);
  };
};

function heartbeat() {
  clearTimeout(timeoutConnection);

  timeoutConnection = setTimeout(() => {
    socket.close();
  }, 30000 + 1000);

  // Envoi 'pong' au serveur pour montrer que le client est toujours connecté
  socket.send(JSON.stringify('pong'));
}

// le message d' inscription a son propre token, pas celui en local storage
export const register = (msg) => {
  socket.send(JSON.stringify(msg));
};

// Fonction générique pour envoyer un message au serveur ws
export const sendMessage = (msg) => {
  if (!gameToken) {
    // On va chercher un token en ls (si il y a)
    gameToken = localStorage.getItem('GAME_TOKEN');
  }

  socket.send(JSON.stringify({
    ...msg,
    token: gameToken,
  }));
};

// Fonction pour wrapper le "onmessage" et faire des actions automatiques
export const setOnMessage = (handleMessage, cb, isRegister = false) => {
  // On va chercher un token en ls
  gameToken = localStorage.getItem('GAME_TOKEN');

  // Si il n'y a pas de connexion WS valide et qu'il n'y a pas de token player en ls
  // alors on arrête là
  if (!isRegister && !socket && !gameToken) return;

  const setHandler = () => {
    socket.onmessage = function({ data }) {
      if (data === 'ping') heartbeat();
  
      let message = null;
      try {
        message = JSON.parse(data);
      } catch {
        return;
      }
  
      handleMessage(message);
    };

    if (!!cb) cb();
  };

  // Si il n'y a pas de connexion WS valide
  // MAIS qu'il y a un token player en ls
  // alors on se reconnecte
  if (!socket && gameToken) {
    init(
      setHandler,
      gameToken,
    );

    return;
  }

  // Sinon on se contente de mettre à jour la fonction pour gérer les messages
  // et exécuter la fonction callback
  setHandler();
};

export const disconnect = () => {
  socket.close();
}

export const cleanUpExcluded = () => {
  // Déconnexion du serveur websocket
  disconnect();

  // Suppression du local storage
  localStorage.clear();
}
