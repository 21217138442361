import './home.css';

import { Link } from 'react-router-dom';

import questionTypeAudioImage from '../../assets/question-type-audio.jpg';
import questionTypeImageImage from '../../assets/question-type-image.jpg';
import questionTypeSimpleImage from '../../assets/question-type-simple.jpg';
import questionTypeTextImage from '../../assets/question-type-text.jpg';
import questionTypeDrawingImage from '../../assets/question-type-drawing.jpg';
import logo from '../../assets/logo.png';
import routeNames from '../../route-names';

export default function Home() {
  return (
    <div className="container-home">
      <section className="container-section-general">
        <section className="container-section main-section">
          <img src={logo} />

          <h3>Le jeu de culture générale où vous êtes l'animateur</h3>
        </section>

        <Link
          className="button-cta"
          to={routeNames.REGISTER}
        >
          Essayez gratuitement
        </Link>
      </section>

      <section className="container-section-general">
        <section className="container-section">
          <h2>Fonctionnalités</h2>

          <div className="container-features">
            <div className="container-feature">
              <h3>Quiz personnalisé</h3>
              <p>Créez des quiz avec vos propres questions ou piochez dans notre base de questions</p>
            </div>

            <div className="container-feature">
              <h3>Questions variées</h3>
              <p>Construisez vos questions librement, ajoutez une image, de l'audio, une zone de dessin, des propositions...</p>
            </div>

            <div className="container-feature">
              <h3>Animateur</h3>
              <p>Vous êtes l'animateur, vous posez les questions, vous validez les réponses</p>
            </div>

            <div className="container-feature">
              <h3>Multijoueur</h3>
              <p>Invitez jusqu'à 10 amis pour participer à vos quiz</p>
            </div>

            <div className="container-feature">
              <h3>Buzzer</h3>
              <p>Les participants ont un buzzer pour prendre la main et répondre aux questions</p>
            </div>
          </div>
        </section>
      </section>

      <section className="container-section-general">
        <section className="container-section">
          <h2>Comment jouer ?</h2>

          <div className="container-single-steps">
            <div className="container-single-step">
              <div className="container-line">
                <div className="line-number">
                  <p>1</p>
                </div>

                <div className="line" />
              </div>

              <div className="container-content">
                <h3>Créer</h3>

                <p>Créez un quiz et remplissez le avec vos propres questions ou piochez dans notre base de questions</p>
              </div>
            </div>

            <div className="container-single-step">
              <div className="container-line">
                <div className="line-number">
                  <p>2</p>
                </div>

                <div className="line" />
              </div>

              <div className="container-content">
                <h3>Inviter</h3>

                <p>Ouvrez une session et envoyez un lien aux participants pour qu'ils puissent rejoindre la session</p>
              </div>
            </div>

            <div className="container-single-step">
              <div className="container-line">
                <div className="line-number">
                  <p>3</p>
                </div>

                <div className="line" />
              </div>

              <div className="container-content">
                <h3>Rejoindre</h3>

                <p>Rejoignez vous en conversation vocale</p>
              </div>
            </div>

            <div className="container-single-step">
              <div className="container-line">
                <div className="line-number">
                  <p>4</p>
                </div>
              </div>

              <div className="container-content">
                <h3>Lancer</h3>

                <p>Une fois que tout le monde est connecté, lancez la session et commencez à poser les questions !</p>
              </div>
            </div>
          </div>

        </section>
      </section>

      <section className="container-section-general">
        <section className="container-section">
          <h1>400+</h1>
          <p>questions en français</p>
        </section>
      </section>

      <section className="container-section-general">
        <section className="container-section">
          <h2>Les différents types de question</h2>

          <div className="container-items-types">
            <div className="container-image">
              <p>Simple question</p>

              <img src={questionTypeSimpleImage} />
            </div>

            <div className="container-image">
              <p>Image</p>

              <img src={questionTypeImageImage} />
            </div>

            <div className="container-image">
              <p>Audio</p>

              <img src={questionTypeAudioImage} />
            </div>

            <div className="container-image">
              <p>Texte</p>

              <img src={questionTypeTextImage} />
            </div>

            <div className="container-image">
              <p>Dessin</p>

              <img src={questionTypeDrawingImage} />
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
