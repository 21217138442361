import * as React from 'react';
import './register.css';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import apiQuizzes from '../../../services/api/quiz';

import * as socket from '../../../services/websocket';
import { ActionBack } from '../../../enums/action-back.enum';
import { ActionAnonymous } from '../../../enums/action-anonymous.enum';

import CustomInput from '../../../components/custom-input';
import CustomLabel from '../../../components/custom-label';
import CustomButton from '../../../components/custom-button';

import logo from '../../../assets/logo.png';
import RouteNames from '../../../route-names';

export default function RegisterGame() {
  const navigate = useNavigate();
  let params = useParams();

  const [quizz, setQuizz] = React.useState(null);
  const [username, setUsername] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    // Récupérer le pseudo en ls
    const lsUsername = localStorage.getItem('PLAYER_USERNAME');
    setUsername(lsUsername);

    // Récupérer les infos de la Game
    getQuizz();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizz = async () => {
    try {
      const lightQuizz = await apiQuizzes.getQuizzByGameToken(params?.token);

      setQuizz(lightQuizz);
    } catch (err) {
      toast.error('Impossible de récupérer les infos du quiz');
    }
  };

  const handleMesage = (message) => {
    if (message?.action === ActionBack.BACK_REGISTER && message?.token) {
      localStorage.setItem('GAME_TOKEN', message?.token);
      localStorage.setItem('PLAYER_ID', message?.playerId);

      navigate(RouteNames.GAME_LOBBY);
    } else if (message?.action === ActionBack.BACK_REGISTER_FAILED) {
      // Déconnecte le socket
      socket.disconnect();

      setLoading(false);

      toast.error('Session pleine ou en cours');
    }
  };

  const register = (event) => {
    event.preventDefault();

    // Vérification que le pseudo n'est pas vide
    if (username?.length <= 0) {
      toast.error(`Le nom d'utilisateur ne doit pas être vide`);
      return;
    }

    // On supprime le potentiel "GAME_TOKEN" en ls pour ne pas se reconnecter avec
    localStorage.removeItem('GAME_TOKEN');
    localStorage.removeItem('PLAYER_ID');

    // On stocke le pseudo en ls
    localStorage.setItem('PLAYER_USERNAME', username);

    // Connexion au serveur ws
    socket.init(connectToWs);
  };

  const connectToWs = () => {
    setLoading(true);

    socket.setOnMessage(
      handleMesage,
      () => {
        socket.register({
          action: ActionAnonymous.REGISTER,
          token: params?.token,
          username,
        });
      },
      true, // signale que l'on veut se connecter pour un register
    );
  };

  return (
    <section className="container-player-register">
      <Link className="container-logo" to="/" target="_blank">
        <img className="logo" src={logo} />
      </Link>

      <h1>Rejoindre "{quizz?.label}"</h1>

      <form onSubmit={register}>
        <CustomLabel label="Nom d'utilisateur" />
        <CustomInput
          value={username}
          onChange={setUsername}
          placeholder="ex : johndoe"
          minLength={1}
          maxLength={32}
          autoFocus
        />

        <CustomButton label="Rejoindre" loading={loading} type="submit" />
      </form>
    </section>
  );
}
