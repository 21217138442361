import * as React from 'react';
import './instructions.css';

export default function Instructions() {
  return (
    <div className="instructions">
      <p>Ecoutez l'animateur</p>

      <p><span>(Vous pouvez utiliser la touche "b" pour buzzer)</span></p>
    </div>
  );
}
